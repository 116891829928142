import { createSelector } from "reselect";
import moment from "moment";
import { uniqBy, uniqWith, flatten, isEqual, head } from "lodash";
import { asListSelector } from "./common";
import { payersByIndexSelector, facilitiesSelector } from "./stays";

export const highCostThreshold = 200; // select a high cost threshold here

export const intakesSelector = (state) => state.intake.all;
export const conditionsSelector = (state) => state.conditions.all;
export const insurancePlansSelector = (state) => {
  const retval = state.insurancePlans.all;
  return retval;
};

export const filteredIntakesSelector = createSelector(
  asListSelector(intakesSelector),
  (state) => state.intake.filter.startDate,
  (state) => state.intake.filter.endDate,
  (intakes, startDate, endDate) => {
    return intakes.filter((intake) => {
      const date = moment(intake.createdAt);
      return (!startDate || date.isSameOrAfter(startDate, "day")) && (!endDate || date.isSameOrBefore(endDate, "day"));
    });
  }
);

export const medicationsForIntake = (intakeId) =>
  createSelector(
    asListSelector((state) => state.meds.all[intakeId]),
    (medications = []) => uniqWith(flatten(medications), isEqual)
  );

export const highCostMedicationsForIntake = (intakeId) =>
  createSelector(
    medicationsForIntake(intakeId),
    (state) => highCostThreshold,
    (medications = [], highCostThreshold) => {
      return medications
        .filter((med) => {
          return med.unitCost * med.quantity > highCostThreshold;
        })
        .sort((a, b) => {
          return b.unitCost * b.quantity - a.unitCost * a.quantity;
        });
    }
  );

export function createWithCostMedicationsSelector(intakeId) {
  return createSelector(medicationsForIntake(intakeId), (medications) =>
    medications.filter((medication) => medication.unitCost)
  );
}

export function createWithoutCostMedicationsSelector(intakeId) {
  return createSelector(medicationsForIntake(intakeId), (medications) =>
    medications.filter((medication) => !medication.unitCost)
  );
}

export const documentsForIntake = (intakeId) =>
  createSelector(
    (state) => state.intake.all[intakeId],
    (intake) => {
      return intake && intake.files;
    }
  );

export const serviceNotesForIntake = (intakeId) =>
  createSelector(
    (state) => state.intake.all[intakeId],
    (intake) => {
      return intake && intake.serviceNotes;
    }
  );

function plansForPayer(planMap, payerId) {
  const payerPlans = planMap[payerId];
  return Object.values(payerPlans || {});
}

export function createPlansForPayerSelector(payerId) {
  return createSelector(insurancePlansSelector, (planMap) => plansForPayer(planMap, payerId));
}

export function createRankedPlansForPayerSelector(payerId) {
  return createSelector(createPlansForPayerSelector(payerId), (plans) => plans.filter((plan) => plan.rank));
}

export const conditionsForIntake = (intakeId) =>
  createSelector(
    asListSelector((state) => state.conditions.all[intakeId]),
    (conditions = {}) => uniqWith(flatten(conditions), isEqual)
  );

export function createConditionsWithIcd10CodeSelector(intakeId) {
  return createSelector(conditionsForIntake(intakeId), (conditions = []) =>
    conditions.filter((condition) => condition.icd10Code)
  );
}

export function createConditionsUniqByIcd10CodeSelector(intakeId) {
  return createSelector(createConditionsWithIcd10CodeSelector(intakeId), (conditions = []) => {
    return uniqBy(conditions, (condition) => {
      return condition.icd10Code;
    });
  });
}

export function createConditionsUniqByIcd10CodeExcludeReturnToProviderSelector(intakeId) {
  return createSelector(createConditionsUniqByIcd10CodeSelector(intakeId), (conditions = []) =>
    conditions.filter((condition) => condition.icd10Code !== "NOT FOUND" && condition.ptotCode !== "Return to Provider")
  );
}

export function createFirstFiveConditionsSelector(intakeId) {
  return createSelector(createConditionsUniqByIcd10CodeExcludeReturnToProviderSelector(intakeId), (conditions) =>
    conditions.slice(0, 5)
  );
}

export function createReturnToProviderConditionsSelector(intakeId) {
  return createSelector(createConditionsUniqByIcd10CodeSelector(intakeId), (conditions) =>
    conditions.filter((condition) => condition.ptotCode === "Return to Provider")
  );
}

export function createNotFoundConditionsSelector(intakeId) {
  return createSelector(createConditionsWithIcd10CodeSelector(intakeId), (conditions) =>
    conditions.filter((condition) => condition.icd10Code === "NOT FOUND")
  );
}

export const intakeSymptomsSelector = (intakeId) =>
  createSelector(
    asListSelector((state) => state.symptoms.all[intakeId]),
    (symptoms = []) => uniqWith(flatten(symptoms), isEqual).map((record) => ({ ...record, ptotCode: "Symptom" }))
  );

export const intakePrimaryPayerSelector = (intakeId) =>
  createSelector(
    (state) => state.intake.all[intakeId],
    asListSelector(payersByIndexSelector),
    (intake = {}, payers) => {
      const { facilityId, primaryPayer } = intake;
      if (facilityId && primaryPayer && payers.length > 0) {
        return head(
          payers.filter((payer) => {
            return payer.name === primaryPayer.name && payer.facilityId === facilityId && payer;
          })
        );
      } else {
        return {};
      }
    }
  );

export const intakesWithPrimaryPayerAndFacilitySelector = createSelector(
  filteredIntakesSelector,
  asListSelector(payersByIndexSelector),
  facilitiesSelector,
  (intakes = [], payers = [], facilities) => {
    return intakes.map((intake) => {
      const facilityName = facilities[intake.facilityId].name;
      const { facilityId, primaryPayer } = intake;
      const displayName =
        payers.length > 0 &&
        (
          head(
            payers.filter((payer) => {
              return payer.name === primaryPayer.name && payer.facilityId === facilityId && payer;
            })
          ) || {}
        ).displayName;
      return {
        ...intake,
        facilityName,
        primaryPayer: {
          ...intake.primaryPayer,
          displayName,
        },
      };
    });
  }
);

//Write a unit test for the following selectors.
export const intakeFacilitiesSelector = createSelector(
  (state) => state.facilities.all,
  filteredIntakesSelector,
  (facilities = {}, intakes = []) => {
    const uniqueIds = Array.from(new Set(intakes.map((intake) => intake.facilityId)));
    return uniqueIds.map((id) => ({ label: facilities[id].name, value: id }));
  }
);
