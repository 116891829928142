import React from "react";

export default () => {
  return (
    <div className="spinner">
      Loading...
      <div />
    </div>
  );
};
