import { FETCH_REPORTS, FETCH_REPORT_CREDS } from "../actions/types";
import { normalize } from "./index";

const initialState = {
  reports: {},
  all: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case `${FETCH_REPORTS}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_REPORTS}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        reports: {
          ...normalize(payload.data, "key"),
        },
      };
    case `${FETCH_REPORT_CREDS}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_REPORT_CREDS}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        all: {
          ...state.all,
          [payload.data.id]: payload.data,
        },
      };
    default:
      return state;
  }
}
