import { FETCH_CONDITIONS, CLEAR_INTAKE_FILE, FETCH_ALL_INTAKE_CONDITIONS } from "../actions/types";
import { defaults } from "lodash";

const initialState = {
  all: {},
};

export default function (state = initialState, { type, payload = {} }) {
  const { intakeId, data, filename } = payload;
  switch (type) {
    case `${FETCH_CONDITIONS}_FULFILLED`:
      return {
        ...state,
        all: {
          ...state.all,
          [intakeId]: {
            ...state.all[intakeId],
            [filename]: [...data],
          },
        },
      };
    case `${FETCH_ALL_INTAKE_CONDITIONS}_FULFILLED`:
      const conditions = {};
      data.forEach((file) => defaults(conditions, file));
      return {
        ...state,
        all: {
          ...state.all,
          [intakeId]: {
            ...state.all[intakeId],
            ...conditions,
          },
        },
      };
    case `${FETCH_CONDITIONS}_REJECTED`:
      return {
        ...state,
        error: payload.error,
      };
    case `${CLEAR_INTAKE_FILE}`:
      return {
        ...state,
        all: {
          ...state.all,
          [intakeId]: {
            ...state.all[intakeId],
            [filename]: [],
          },
        },
      };
    default:
      return state;
  }
}
