import { FETCH_UPDATES, SET_UPDATE_DATE_FILTER, CREATE_UPDATE, DELETE_UPDATE, UPDATE_UPDATE } from "../actions/types";
import { normalize } from "./index";
import moment from "moment";
import { pick, keys } from "lodash";

const initialState = {
  all: {},
  filter: {
    startDate: moment().subtract(30, "days"),
    endDate: moment().add(30, "days"),
  },
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_UPDATE_DATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
      };
    case `${FETCH_UPDATES}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_UPDATES}_FULFILLED`:
      const updates = payload.data.map(({ stay, ...rest }) => {
        return {
          ...rest,
          stayId: stay.id,
        };
      });
      return {
        ...state,
        isLoading: false,
        all: { ...state.all, ...normalize(updates) },
      };
    case `${CREATE_UPDATE}_FULFILLED`:
    case `${UPDATE_UPDATE}_FULFILLED`:
      const { stay, ...rest } = payload.data;
      return {
        ...state,
        all: {
          ...state.all,
          [rest.id]: { ...rest, stayId: stay.id },
        },
      };
    case `${DELETE_UPDATE}_FULFILLED`:
      const deletedUpdateId = payload && payload.data && payload.data.deletedUpdateId;
      const filteredUpdates = pick(
        state.all,
        keys(state.all).filter((id) => id !== deletedUpdateId)
      );
      return {
        ...state,
        all: filteredUpdates,
      };
    default:
      return state;
  }
}
