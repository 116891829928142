import React from "react";
import { Link } from "react-router-dom";
import Panel from "../../common/Panel";
import HighCostMedications from "./HighCostMedications";
import Conditions from "../Conditions";
import { isToggleTurnedOn } from "../../common/FeatureToggle";
import { createFirstFiveConditionsSelector } from "../../store/selectors";

export default function Insights(props) {
  return (
    <>
      <h3>Insights</h3>
      <div className="d-flex flex-wrap">
        <Panel className="flex-1-auto col-md-6 col-sm-12 text-center">
          <HighCostMedications intakeId={props.intakeId} />
          <Link to={`${props.match.url}/medications`} className="m-t-xs btn btn-primary btn-block">
            See All Medications <i className="fa fa-chevron-right" />
          </Link>
        </Panel>
        {isToggleTurnedOn("CONDITIONS_INSIGHTS") && (
          <Panel className="flex-1-auto col-md-6 col-sm-12 text-center">
            <div className="h4 m-t m-b-lg">Conditions</div>
            <Conditions intakeId={props.intakeId} intakeConditionsSelector={createFirstFiveConditionsSelector} />
            <Link to={`${props.match.url}/conditions`} className="m-t-xs btn btn-primary btn-block">
              See All Conditions <i className="fa fa-chevron-right" />
            </Link>
          </Panel>
        )}
      </div>
    </>
  );
}
