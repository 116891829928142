import React from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, ResponsiveContainer } from "recharts";
import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent";
import ToggleButton from "../../common/ToggleButton";

const CustomTooltip = (props) => {
  if (!props.active) {
    return null;
  }

  const toolTipPayload = props.payload[0] ? props.payload[0].payload : {};
  const payload = [
    {
      name: "Date/Time",
      value: toolTipPayload.dateTime,
    },
    {
      name: "ADL Index",
      value: toolTipPayload.adlIndex,
    },
    {
      name: "Days in Stay",
      value: toolTipPayload.dayInStay,
    },
  ];

  return <DefaultTooltipContent payload={payload} />;
};

export class ADLsGraph extends React.Component {
  state = {
    chartType: "Dates In Stay",
  };

  toggleChartType = (type) => {
    this.setState({
      chartType: type,
    });
  };

  render() {
    const showDates = this.state.chartType === "Dates In Stay";

    return this.props.data.length > 0 ? (
      <>
        <ResponsiveContainer width={this.props.width || 500} height={this.props.height || 250}>
          <BarChart data={this.props.data}>
            <CartesianGrid stroke="1" />

            <XAxis dataKey={showDates ? "dateTime" : "dayInStay"} />
            <YAxis domain={[0, "dataMax + 10"]} />

            <Tooltip content={CustomTooltip} />
            <Bar dataKey="adlIndex" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>

        <ToggleButton
          titleLeft="Days In Stay"
          titleRight="Dates In Stay"
          value={this.state.chartType}
          onToggle={this.toggleChartType}
        />
      </>
    ) : (
      <p className="m-l-sm m-b-none" style={{ marginTop: 10 }}>
        There is no data for this patient.
      </p>
    );
  }
}

export default ADLsGraph;
