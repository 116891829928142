import {
  FETCH_STAY,
  FETCH_STAYS,
  FETCH_STAYS_BY_FACILITY,
  FETCH_UPDATES,
  SET_STAY_DATE_FILTER,
  UPDATE_STAY,
  CREATE_UPDATE,
  FETCH_STAY_BY_PATIENT_AND_DATE,
} from "../actions/types";
import { normalize } from "./index";

const initialState = {
  all: {},
  filter: {
    startDate: undefined,
    endDate: undefined,
  },
};

const mergeStays = (currentState, newStays) => ({
  ...currentState,
  all: { ...currentState.all, ...normalize(newStays) },
  isLoading: false,
});

const mergeStay = (currentState, newStay) => ({
  ...currentState,
  all: {
    ...currentState.all,
    [newStay.id]: newStay,
  },
});

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_STAY_DATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
      };
    case `${FETCH_UPDATES}_FULFILLED`:
      const stays = payload.data
        .map((update) => {
          return update.stay;
        })
        .filter((e) => !!e);
      return mergeStays(state, stays);
    case `${FETCH_STAYS}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_STAYS}_FULFILLED`:
      return mergeStays(state, payload.data);
    case `${FETCH_STAYS_BY_FACILITY}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_STAYS_BY_FACILITY}_FULFILLED`:
      return {
        ...state,
        all: { ...normalize(payload.data) },
        isLoading: false,
      };
    case `${CREATE_UPDATE}_FULFILLED`:
      return mergeStay(state, payload.data.stay);
    case `${FETCH_STAY_BY_PATIENT_AND_DATE}_FULFILLED`:
    case `${FETCH_STAY}_FULFILLED`:
    case `${UPDATE_STAY}_FULFILLED`:
      return mergeStay(state, payload.data);
    default:
      return state;
  }
}
