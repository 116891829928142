import React from "react";
import { connect } from "react-redux";
import DeleteIconbutton from "../common/DeleteIconButton";
import { useFullName } from "../utilities";
import { deleteIntake } from "../store/actions";

function DeleteIntakeButton({ intake, deleteIntake }) {
  return (
    <DeleteIconbutton
      itemId={intake.id}
      title="Are you sure?"
      description={`You are about to delete ${useFullName(intake)}.`}
      deleteItemAction={deleteIntake}
    />
  );
}

export default connect(null, { deleteIntake })(DeleteIntakeButton);
