import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Formik, FieldArray } from "formik";
import Filter from "../common/Filter";
import TextInput from "../common/TextInput";
import TextareaInput from "../common/TextareaInput";
import ClinicalServicesCheckboxes from "./ClinicalServicesCheckboxes";
import { fetchFacilities, fetchDiagnoses, fetchPayers, createIntake, updateIntake } from "../store/actions";
import { diagnosesSelector, sortedByDisplayNameAndNameTopPayersSelector } from "../store/selectors";
import { debounce, upperFirst, toLower } from "lodash";
import * as Yup from "yup";
import FileInput from "../common/FileInput";
import IcdOptionsMenu from "./IcdOptionsMenu";
import IcdOptionsInfo from "./IcdOptionsInfo";
import { uploadFile } from "../utilities";
import { withMixpanel } from "react-mixpanel-browser";
import { statusOptions } from "./constants";

export class IntakeForm extends React.Component {
  constructor(props) {
    super(props);
    const { fetchDiagnoses, primaryPayer = {} } = this.props;
    this.debouncedFetchDiagnoses = debounce((value) => {
      fetchDiagnoses(value);
    }, 1000);
    this.state = {
      documentModalOpen: false,
      previousQuery: "",
      selectedFacilityId: null,
      selectedPrimaryPayerId: primaryPayer.id,
      primaryPayerServices: primaryPayer.plans,
    };
  }

  handleInputChange = (query, options) => {
    const { fetchDiagnoses } = this.props;
    const { previousQuery } = this.state;
    options === "maxList"
      ? query && typeof fetchDiagnoses === "function" && fetchDiagnoses(query, options)
      : query && query !== previousQuery && typeof fetchDiagnoses === "function" && this.debouncedFetchDiagnoses(query);
    this.setState((prevState) => ({
      ...prevState,
      previousQuery: query ? query : previousQuery,
    }));
  };

  setStateValue = (fieldName, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  submitForm = async (values, actions) => {
    const {
      facilityId,
      primaryPayerName,
      primaryPayerServices,
      firstName,
      lastName,
      secondaryPayer,
      documents,
      ...rest
    } = values;
    const { id, files: uploadedFiles, history, primaryPayer, mixpanel, user: { email } = {} } = this.props;
    const plans = primaryPayer && primaryPayer.plans;
    const services = primaryPayerServices ? primaryPayerServices : { plans };

    try {
      let intakeFiles = [];
      if (!uploadedFiles && documents) {
        intakeFiles = await Promise.all(
          documents.map((file) => {
            return uploadFile(file, facilityId);
          })
        );
        mixpanel.track("document upload", { email: email });
      }
      const data = {
        ...rest,
        facilityId,
        primaryPayer: {
          name: primaryPayerName,
          ...services,
        },
        secondaryPayer: typeof secondaryPayer === "string" ? secondaryPayer : "",
        firstName: upperFirst(firstName),
        lastName: upperFirst(lastName),
        files: id ? uploadedFiles : intakeFiles,
      };
      if (data.primaryPayer.plans === undefined) {
        data.primaryPayer.plans = [];
      }
      id ? await this.props.updateIntake(id, data) : await this.props.createIntake(data);
      id && history.goBack();
    } catch (e) {
      console.error(e);
    } finally {
      actions.setSubmitting(false);
      mixpanel.track("intake form submission", { email: email });
    }
  };

  render() {
    const {
      facilities,
      diagnoses = [],
      files = [],
      id,
      firstName,
      lastName,
      referralSource,
      primaryDiagnosis,
      additionalDiagnoses = [],
      facilityId,
      primaryPayer = {},
      secondaryPayer,
      insuranceAuthorizationNumber,
      insuranceNumber,
      serviceNotes,
      diagnosesTotal,
      status,
      sortedByDisplayNameAndNameTopPayers,
    } = this.props;
    const { selectedFacilityId, selectedPrimaryPayerId, previousQuery } = this.state;
    const serviceNotesDescription = `The payer may require additional information about the services
      provided in advance of authorization, such as IV dosage, tube feeding rate and formula, wound stage, etc.
      Please note these details here`;
    const facilityOptions =
      facilities && Object.values(facilities).map((facility) => ({ label: facility.name, value: facility.id }));
    const payersOptions =
      ((selectedFacilityId || facilityId) &&
        Object.keys(sortedByDisplayNameAndNameTopPayers)
          .filter((key) =>
            Object.values(sortedByDisplayNameAndNameTopPayers[key]).some(
              (payer) => payer.facilityId === (selectedFacilityId || facilityId)
            )
          )
          .map((payerDisplayName) => {
            const payer = sortedByDisplayNameAndNameTopPayers[payerDisplayName].filter(
              (payer) => payer.facilityId === (selectedFacilityId || facilityId)
            )[0];
            const { name, id } = payer;
            return { label: payerDisplayName, value: { id, name } };
          })) ||
      [];
    const diagnosesOptions =
      diagnoses instanceof Array
        ? diagnoses.map((diagnosis) => {
            const { description, code } = diagnosis;
            return { label: `${description} (${code})`, value: { code, description } };
          })
        : [];
    const customOptions = [];
    diagnosesOptions.length &&
      diagnosesOptions.length <= 20 &&
      diagnosesTotal > 20 &&
      customOptions.push(<IcdOptionsMenu fetchMaxIcdList={this.handleInputChange} query={previousQuery} />);
    diagnosesOptions.length &&
      customOptions.push(<IcdOptionsInfo diagnosesShown={diagnosesOptions.length} diagnosesTotal={diagnosesTotal} />);

    return (
      <Formik
        initialValues={{
          firstName: firstName || "",
          lastName: lastName || "",
          referralSource: referralSource || "",
          status: status || "PENDING",
          primaryDiagnosis:
            (primaryDiagnosis && {
              code: primaryDiagnosis.code,
              description: primaryDiagnosis.description,
            }) ||
            null,
          additionalDiagnoses: additionalDiagnoses,
          facilityId: facilityId || null,
          primaryPayerName: primaryPayer.name || "",
          secondaryPayer: secondaryPayer || "",
          insuranceAuthorizationNumber: insuranceAuthorizationNumber || "",
          insuranceNumber: insuranceNumber || "",
          serviceNotes: serviceNotes || "",
          documents: id ? [] : files,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          facilityId: Yup.string().nullable().required("Please choose a facility"),
          firstName: Yup.string().required("First Name is required"),
          lastName: Yup.string().required("Last Name is required"),
          referralSource: Yup.string(),
          status: Yup.string().required("Status is required"),
          primaryDiagnosis: Yup.object().nullable().notRequired(),
          additionalDiagnoses: Yup.array().notRequired(),
          primaryPayerName: Yup.string().required("Primary Payer is required"),
          secondaryPayer: Yup.string().nullable().notRequired(),
          documents: Yup.array(
            Yup.object({
              file: Yup.mixed().required(),
              types: Yup.array(Yup.string()).required("At least one document type must be specified"),
            })
          ).notRequired(),
        })}
        onSubmit={this.submitForm}
        render={({
          values,
          handleSubmit,
          isValidating,
          isSubmitting,
          handleChange,
          touched,
          errors,
          setFieldTouched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <button
              type="submit"
              className={`btn btn-primary m-t w-lg ld-ext-left ${!isValidating && isSubmitting ? "running" : ""}`}
              disabled={isSubmitting}
            >
              Save
              {!isValidating && isSubmitting && <div className="ld ld-ring ld-spin"></div>}
            </button>
            <Link to={"/intakes"} type="button" className="btn btn-default m-t m-l w-lg">
              Cancel
            </Link>
            <h3>Patient Information</h3>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <TextInput name="firstName" label="First Name" {...{ values, touched, errors, handleChange }} />
              </div>
              <div className="col-xs-12 col-sm-6">
                <TextInput name="lastName" label="Last Name" {...{ values, touched, errors, handleChange }} />
              </div>
            </div>
            <TextInput name="referralSource" label="Referral Source" {...{ values, touched, errors, handleChange }} />
            <h3>Status</h3>
            <div className="form-group">
              <Filter
                selectHeight="38px"
                defaultValue={{
                  label: upperFirst(toLower(status)) || "Pending",
                  value: status || "PENDING",
                }}
                isDisabled={!primaryPayer.id}
                disabledPlaceholder="Pending"
                key={status}
                options={statusOptions}
                onChange={(option) => setFieldValue("status", option.value)}
                onBlur={() => setFieldTouched("status", true)}
              />
              {touched.status && errors.status && <div className="help-block text-danger">{errors.status}</div>}
            </div>
            <h3>Diagnoses</h3>
            <div className="form-group">
              <label className="control-label" htmlFor="primaryDiagnosis">
                Primary Diagnosis
              </label>
              <Filter
                selectHeight="38px"
                isClearable
                isSearchable
                defaultValue={
                  primaryDiagnosis && {
                    label: `${primaryDiagnosis.description} (${primaryDiagnosis.code})`,
                    value: null,
                  }
                }
                key={primaryDiagnosis && primaryDiagnosis.code}
                options={diagnosesOptions}
                customOptions={customOptions}
                onInputChange={this.handleInputChange}
                onChange={(option) => setFieldValue("primaryDiagnosis", option ? option.value : null)}
                onBlur={() => setFieldTouched("primaryDiagnosis", true)}
              />
              {touched.primaryDiagnosis && errors.primaryDiagnosis && (
                <div className="help-block text-danger">{errors.primaryDiagnosis}</div>
              )}
            </div>
            {values.primaryDiagnosis && (
              <div className="form-group">
                <label className="control-label" htmlFor="additionalDiagnoses">
                  Additional Diagnoses (optional)
                </label>
                <Filter
                  selectHeight="38px"
                  isMulti
                  options={diagnosesOptions}
                  customOptions={customOptions}
                  defaultValue={
                    additionalDiagnoses.length &&
                    additionalDiagnoses.map((diagnosis) => {
                      const { description, code } = diagnosis;
                      return { label: `${description} (${code})`, value: { code, description } };
                    })
                  }
                  onInputChange={this.handleInputChange}
                  onChange={(options) =>
                    setFieldValue(
                      "additionalDiagnoses",
                      options.map((opt) => opt.value)
                    )
                  }
                  onBlur={() => setFieldTouched("additionalDiagnoses", true)}
                  getOptionValue={(opt) => opt.value.code}
                />
                {touched.additionalDiagnoses && errors.additionalDiagnoses && (
                  <div className="help-block text-danger">{errors.additionalDiagnoses}</div>
                )}
              </div>
            )}
            <h3>Facility</h3>
            <div className="form-group">
              <Filter
                selectHeight="38px"
                defaultValue={
                  facilityId &&
                  facilityOptions.length && {
                    label: facilityOptions.find((option) => option.value === facilityId).label,
                    value: facilityId,
                  }
                }
                key={`${facilityId}${facilityOptions.length}`}
                options={facilityOptions}
                onChange={(option) => {
                  setFieldValue("facilityId", option.value);
                  this.setStateValue("selectedFacilityId", option.value);
                  this.setStateValue("selectedPrimaryPayerId", null);
                }}
                onBlur={() => setFieldTouched("facilityId", true)}
              />
              {touched.facilityId && errors.facilityId && (
                <div className="help-block text-danger">{errors.facilityId}</div>
              )}
            </div>
            <h3>Insurance</h3>
            <div className="form-group">
              <label className="control-label" htmlFor="primaryPayer">
                Primary Payer
              </label>
              <Filter
                selectHeight="38px"
                isDisabled={!selectedFacilityId && !facilityId}
                disabledPlaceholder="Please select a facility."
                isClearable
                defaultValue={{
                  label: primaryPayer.name,
                  value: null,
                }}
                key={primaryPayer.id}
                options={payersOptions}
                onChange={(option) => {
                  if (option) {
                    setFieldValue("primaryPayerName", option.value.name);
                    this.setStateValue("selectedPrimaryPayerId", option.value.id);
                  }
                }}
                onBlur={() => setFieldTouched("primaryPayerName", true)}
              />
              {touched.primaryPayerName && errors.primaryPayerName && (
                <div className="help-block text-danger">{errors.primaryPayerName}</div>
              )}
            </div>
            {(selectedPrimaryPayerId || primaryPayer.id) && (!secondaryPayer || secondaryPayer.length > 0) && (
              <div className="form-group">
                <label className="control-label" htmlFor="secondaryPayer">
                  Secondary Payer
                </label>
                <Filter
                  selectHeight="38px"
                  isClearable
                  defaultValue={
                    secondaryPayer && {
                      label: secondaryPayer,
                      value: null,
                    }
                  }
                  key={secondaryPayer}
                  options={payersOptions}
                  onChange={(option) => setFieldValue("secondaryPayer", option ? option.value.name : "")}
                  onBlur={() => setFieldTouched("secondaryPayer", true)}
                />
                {touched.secondaryPayer && errors.secondaryPayer && (
                  <div className="help-block text-danger">{errors.secondaryPayer}</div>
                )}
              </div>
            )}
            <TextInput
              name="insuranceAuthorizationNumber"
              label="Insurance Authorization Number"
              {...{ values, touched, errors, handleChange }}
            />
            <TextInput name="insuranceNumber" label="Insurance Number" {...{ values, touched, errors, handleChange }} />
            {(selectedPrimaryPayerId || primaryPayer.id) && (
              <ClinicalServicesCheckboxes
                payerId={selectedPrimaryPayerId || primaryPayer.id}
                onChange={(servicesValues) => setFieldValue("primaryPayerServices", servicesValues)}
                primaryPayerPlans={primaryPayer.plans}
              />
            )}
            {!id && (
              <>
                <h3>Documents</h3>
                <div className="m-b-sm">
                  <p>
                    <label className="control-label">Documents required to start a pre-cert include:</label>
                    <br />- H&P within the last 7 days
                    <br />- Nursing notes within the last 48 hours
                    <br />- Medication list
                    <br />- PT, OT, ST evals and notes within the last 48 hours
                  </p>
                </div>
                <FieldArray
                  name="documents"
                  render={(arrHelpers) => (
                    <div className="m-b-sm">
                      <FileInput
                        files={values.documents}
                        onFilesAdded={(files) => files.forEach((f) => arrHelpers.push(f))}
                        onFileTypesChanged={(index, types) => {
                          const doc = values.documents[index];
                          arrHelpers.replace(index, { ...doc, types });
                          setFieldTouched(`documents[${index}]`);
                        }}
                        onFileRemoved={arrHelpers.remove}
                        touched={touched.documents}
                        errors={errors.documents}
                      />
                    </div>
                  )}
                />
              </>
            )}
            <TextareaInput label="Service Notes" name="serviceNotes" hasInfo description={serviceNotesDescription} />
            <button
              type="submit"
              className={`btn btn-primary m-t w-lg ld-ext-left ${!isValidating && isSubmitting ? "running" : ""}`}
              disabled={isSubmitting}
            >
              Save
              {!isValidating && isSubmitting && <div className="ld ld-ring ld-spin"></div>}
            </button>
            <Link to={"/intakes"} type="button" className="btn btn-default m-t m-l w-lg">
              Cancel
            </Link>
          </form>
        )}
      />
    );
  }
}

IntakeForm.propTypes = {
  facilities: PropTypes.object,
  payers: PropTypes.array,
  diagnoses: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

function mapStateToProps(state) {
  const { diagnosesTotal } = state.diagnoses;
  return {
    facilities: state.facilities.all,
    sortedByDisplayNameAndNameTopPayers: sortedByDisplayNameAndNameTopPayersSelector(state),
    diagnoses: diagnosesSelector(state),
    diagnosesTotal,
    user: state.user,
  };
}

export default withRouter(
  connect(mapStateToProps, { fetchFacilities, fetchPayers, fetchDiagnoses, createIntake, updateIntake })(
    withMixpanel(IntakeForm)
  )
);
