import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StaysList from "./patient/StaysList";
import { staysPayersSelector, staysLocationSelector } from "./store/selectors";
import { fetchFacilities, setStayDateFilterAndGetStaysByFacility, setCustomFilters } from "./store/actions";
import { DateFilter } from "./common/DateFilter";
import { withMixpanel } from "react-mixpanel-browser";

export const PatientsPage = (props) => {
  const { facility, startDate, endDate, setStayDateFilterAndGetStaysByFacility } = props;

  let facilityName = "Facility Name Placeholder";
  if (facility) {
    facilityName = facility.name;
  }

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }} className="m-b-sm">
        <h1 className="h2 text-primary" style={{ flex: "1 0 auto" }}>
          Patients
        </h1>
        <DateFilter
          endDate={endDate}
          startDate={startDate}
          facility={facility}
          setDateFilter={setStayDateFilterAndGetStaysByFacility}
        />
      </div>
      <h3 className="h5 text-primary" style={{ flex: "1 0 auto" }}>
        {facilityName} (<Link to={`/patients`}>Return to Facility Select</Link>)
      </h3>
      <div className="hpanel">
        <div className="panel-body no-padding">
          <StaysList startDate={startDate} endDate={endDate} facility={facility} />
        </div>
      </div>
    </>
  );
};

PatientsPage.propTypes = {
  facility: PropTypes.object,
  stays: PropTypes.array,
  payers: PropTypes.array,
  locations: PropTypes.array,
};

function mapStateToProps(state) {
  const {
    facilities: { all, facLoading },
    filters,
    stays: {
      isLoading,
      filter: { startDate, endDate },
    },
  } = state;
  return {
    facilities: all,
    payers: staysPayersSelector(state),
    locations: staysLocationSelector(state),
    startDate,
    endDate,
    filters,
    isLoading: isLoading || facLoading,
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  fetchFacilities,
  setStayDateFilterAndGetStaysByFacility,
  setCustomFilters,
})(withMixpanel(PatientsPage));
