import { GET_CURRENT_AUTHENTICATED_USER } from "../actions/types";

const initialState = {};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case `${GET_CURRENT_AUTHENTICATED_USER}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_CURRENT_AUTHENTICATED_USER}_FULFILLED`:
      const { attributes: { email } = {} } = payload;
      return {
        ...state,
        isLoading: false,
        email,
      };
    default:
      return state;
  }
}
