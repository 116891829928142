import {
  FETCH_INTAKE,
  FETCH_INTAKES,
  SET_INTAKE_DATE_FILTER,
  CREATE_INTAKE,
  CLEAR_NEW_INTAKE,
  UPDATE_INTAKE,
  DELETE_INTAKE,
} from "../actions/types";
import { normalize } from "./index";
import { pick, keys } from "lodash";
import moment from "moment";

const initialState = {
  all: {},
  filter: {
    startDate: moment().subtract(2, "week").hour(0).minute(0).second(0).millisecond(0),
    endDate: null,
  },
  newIntakeId: null,
};

export default function (state = initialState, { type, payload }) {
  const intakeId = payload && payload.data && payload.data.id;
  switch (type) {
    case SET_INTAKE_DATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
      };
    case `${FETCH_INTAKES}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_INTAKES}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        all: normalize(
          payload.data.map((intake) => {
            const oldIntake = state.all[intake.id] || {};
            const { primaryPayerName, ...rest } = intake;
            return { ...oldIntake, primaryPayer: { ...oldIntake.primaryPayer, name: primaryPayerName }, ...rest };
          })
        ),
      };
    case `${FETCH_INTAKE}_FULFILLED`:
    case `${UPDATE_INTAKE}_FULFILLED`:
      const oldIntake = state.all[intakeId];
      return {
        ...state,
        all: {
          ...state.all,
          [intakeId]: {
            ...oldIntake,
            ...payload.data,
          },
        },
      };
    case `${CREATE_INTAKE}_FULFILLED`:
      return {
        ...state,
        all: {
          ...state.all,
          [intakeId]: payload.data,
        },
        newIntakeId: intakeId,
      };
    case `${CLEAR_NEW_INTAKE}`:
      return {
        ...state,
        newIntakeId: null,
      };
    case `${DELETE_INTAKE}_FULFILLED`:
      const deletedIntakeId = payload && payload.data && payload.data.deletedIntakeId;
      const filteredIntakes = pick(
        state.all,
        keys(state.all).filter((id) => id !== deletedIntakeId)
      );
      return {
        ...state,
        all: filteredIntakes,
      };
    default:
      return state;
  }
}
