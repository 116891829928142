import { FETCH_DIAGNOSES } from "../actions/types";

const initialState = {
  all: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case `${FETCH_DIAGNOSES}_FULFILLED`:
      const diagnoses = payload[3].map((diagnosis) => ({ code: diagnosis[0], description: diagnosis[1] }));
      return {
        ...state,
        all: diagnoses,
        diagnosesTotal: payload[0],
      };
    default:
      return state;
  }
}
