import React, { useEffect, useState } from "react";
import moment from "moment";
import Table from "../common/Table";
import { DateFilter } from "../common/DateFilter";
import LoadingIndicator from "../common/LoadingIndicator";
import RateHistoryDialog from "./RateHistoryDialog";
import { usePayers, useRateHistoryForStay } from "../api/caseManagementApi";

const config = {
  columns: [
    {
      title: "Effective From Date",
      key: "effectiveFromDate",
      transformer: (colVal) => moment(colVal).format("M/DD/YYYY"),
      sortValue: ({ effectiveFromDate }) => moment(effectiveFromDate).unix(),
      sortable: true,
    },
    {
      title: "Primary Payer",
      key: "payer",
      sortable: true,
      transformer: (colVal) => {
        return colVal && colVal.displayName;
      },
    },
    {
      title: "Care Level",
      key: "careLevel",
      sortable: true,
    },
    {
      title: "Daily Reimbursement",
      key: "dailyReimbursementRate",
      transformer: (colVal) => colVal && `$${colVal.toFixed(2)}`,
      sortable: true,
      sortValue: ({ dailyReimbursementRate }) => dailyReimbursementRate || 0,
    },
    {
      title: "Edit",
      transformer: (rateHistory) => {
        return <RateHistoryDialog rateHistory={rateHistory} />;
      },
    },
  ],
  defaultSort: {
    column: 0,
    direction: "descending",
  },
};

export const RateHistory = (props) => {
  const { stay, insurancePlans, patientPayers } = props;

  const [filters, setFilters] = useState({ startDate: null, endDate: null });
  const [payers, setPayers] = useState(undefined);

  const rateHistoryQuery = useRateHistoryForStay(stay.id);
  const payersQuery = usePayers(patientPayers);

  const payersFetched = payersQuery.every((x) => x.isSuccess);

  useEffect(() => {
    if (payersFetched) {
      const data = payersQuery.flatMap((x) => x.data);
      setPayers(data);
    }
  }, [payersFetched]);

  if (rateHistoryQuery.isLoading || !payers) {
    return <LoadingIndicator />;
  }

  const handleDateFilterChange = (selectedValue) => {
    setFilters({ startDate: selectedValue.startDate, endDate: selectedValue.endDate });
  };

  const joinedHistoryData = rateHistoryQuery.data.map((rh) => {
    const matchingPlan = insurancePlans ? insurancePlans.find((x) => x.id === rh.planId) : null;
    const matchingPayer = payers.find((x) => x.id === rh.payerId);
    return { ...rh, payer: matchingPayer, insurancePlan: matchingPlan };
  });

  const visibleRateHistory = joinedHistoryData.filter((record) => {
    const date = moment(record.effectiveFromDate);
    return (
      (!filters.startDate || date.isSameOrAfter(filters.startDate, "day")) &&
      (!filters.endDate || date.isSameOrBefore(filters.endDate, "day"))
    );
  });

  return rateHistoryQuery.data.length > 0 ? (
    <>
      <div className="d-flex justify-end p-xs">
        <DateFilter endDate={filters.endDate} startDate={filters.startDate} setDateFilter={handleDateFilterChange} />
      </div>
      <Table data={visibleRateHistory} config={config} />
    </>
  ) : (
    <div style={{ marginTop: 20 }}>There is no rate history for this patient.</div>
  );
};
