import { combineReducers } from "redux";
import alertsReducer from "./alerts";
import { payersReducer, insurancePlansReducer } from "./insurance";
import ordersReducer from "./orders";
import staysReducer from "./stays";
import updatesReducer from "./updates";
import nurseNotesReducer from "./nurseNotes";
import therapyEventsReducer from "./therapyEvents";
import intakeReducer from "./intake";
import patientsReducer from "./patients";
import filtersReducer from "./filters";
import reportsReducer from "./reports";
import facilitiesReducer from "./facilities";
import medsReducer from "./meds";
import diagnosesReducer from "./diagnoses";
import conditionsReducer from "./conditions";
import rateHistoryReducer from "./rateHistory";
import userReducer from "./user";
import serviceHistoryReducer from "./serviceHistory";
import symptomsReducer from "./symptoms";
import adlsReducer from "./adls";
import assessmentsListReducer from "./assessmentsList";
import stayDiagnosesReducer from "./stayDiagnoses";

export const normalize = (data = [], key = "id") => {
  return (
    data.constructor === Array &&
    data.reduce((result, obj) => {
      return {
        ...result,
        [obj[key]]: obj,
      };
    }, {})
  );
};

export default combineReducers({
  alerts: alertsReducer,
  payers: payersReducer,
  insurancePlans: insurancePlansReducer,
  intake: intakeReducer,
  orders: ordersReducer,
  stays: staysReducer,
  nurseNotes: nurseNotesReducer,
  therapyEvents: therapyEventsReducer,
  updates: updatesReducer,
  patients: patientsReducer,
  filters: filtersReducer,
  reports: reportsReducer,
  facilities: facilitiesReducer,
  meds: medsReducer,
  diagnoses: diagnosesReducer,
  conditions: conditionsReducer,
  rateHistory: rateHistoryReducer,
  user: userReducer,
  serviceHistory: serviceHistoryReducer,
  symptoms: symptomsReducer,
  adls: adlsReducer,
  assessmentsList: assessmentsListReducer,
  stayDiagnoses: stayDiagnosesReducer,
});
