import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Link } from "react-router-dom";
import PropTypes from "prop-types";
import IntakesList from "./IntakesList";
import { DateFilter } from "../common/DateFilter";
import { fetchIntakes, fetchPayers, setIntakeDateFilterAndFetchIntakes, fetchFacilities } from "../store/actions";
import { intakesWithPrimaryPayerAndFacilitySelector, intakeFacilitiesSelector } from "../store/selectors";
import NewIntake from "./NewIntake";
import EditIntake from "./EditIntake";
import IntakePage from "./IntakePage";

export class IntakesPage extends Component {
  componentDidMount() {
    const {
      startDate,
      endDate,
      fetchIntakes,
      fetchFacilities,
      fetchPayers,
      intakes = [],
      facilities = [],
      payers = [],
    } = this.props;
    !intakes.length &&
      typeof fetchIntakes === "function" &&
      fetchIntakes(startDate, endDate).catch((err) => console.error(err));
    !facilities.length && typeof fetchFacilities === "function" && fetchFacilities().catch((err) => console.error(err));
    !payers.length && typeof fetchPayers === "function" && fetchPayers().catch((err) => console.error(err));
  }

  render() {
    const { match: { path, url } = {} } = this.props;
    return (
      <Switch>
        <Route
          exact
          path={path}
          component={() => {
            return (
              <>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }} className="m-b-sm">
                  <h1 className="h2 text-primary" style={{ flex: "1 0 auto" }}>
                    Intake
                  </h1>
                  <Link to={`${url}/create/new`} className="btn btn-primary m-r-sm">
                    Add Patient
                  </Link>
                  <DateFilter
                    endDate={this.props.endDate}
                    startDate={this.props.startDate}
                    setDateFilter={this.props.setIntakeDateFilterAndFetchIntakes}
                  />
                </div>
                <div className="hpanel">
                  <div className="panel-body no-padding">
                    <IntakesList {...this.props} />
                  </div>
                </div>
              </>
            );
          }}
        />
        <Route exact path={`${path}/edit/:id`} component={EditIntake} />
        <Route exact path={`${path}/create/new`} component={NewIntake} />
        <Route path={`${path}/:id`} component={IntakePage} />
      </Switch>
    );
  }
}

IntakesPage.propTypes = {
  intakes: PropTypes.array,
};

function mapStateToProps(state) {
  const {
    intake: {
      isLoading: intakeIsLoading,
      filter: { startDate, endDate },
    },
    facilities: { isLoading: facIsLoading },
  } = state;
  const props = {
    startDate,
    endDate,
    isLoading: intakeIsLoading || facIsLoading,
  };
  if (!props.isLoading) {
    props.intakesWithPrimaryPayerAndFacility = intakesWithPrimaryPayerAndFacilitySelector(state);
    props.facilities = intakeFacilitiesSelector(state);
  }
  return props;
}

export default connect(mapStateToProps, {
  fetchIntakes,
  setIntakeDateFilterAndFetchIntakes,
  fetchFacilities,
  fetchPayers,
})(IntakesPage);
