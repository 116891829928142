import React from "react";
import { Field } from "formik";

export default ({ label, name, description, hasInfo, touched, errors, ...rest }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      {description && (
        <div className="d-flex m-t-xs m-b">
          {hasInfo && <i className="fa fa-2x fa-info-circle text-primary" aria-label="Info" />}
          <div className="p-xxs">{description}</div>
        </div>
      )}
      <Field component="textarea" name={name} className="form-control" {...rest} />
      {touched && errors && touched[name] && errors[name] && (
        <div className="help-block text-danger">{errors[name]}</div>
      )}
    </div>
  );
};
