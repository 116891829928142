import React, { Component } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router-dom";
import { fetchAnalyticsReportCreds } from "./store/actions";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

export class ReportDisplayPage extends Component {
  componentDidMount() {
    const { id, fetchAnalyticsReportCreds } = this.props;
    typeof fetchAnalyticsReportCreds == "function" &&
      id &&
      fetchAnalyticsReportCreds(id).catch((err) => console.log(err));
  }

  componentDidUpdate(prevProps) {
    const {
      fetchAnalyticsReportCreds,
      location: { pathname },
    } = this.props;
    const reportId = this.getParams(pathname).id;
    if (reportId && reportId !== prevProps.match.params.id) {
      fetchAnalyticsReportCreds(reportId);
    }
  }

  getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
      path: `/reports/:id`,
    });
    return (matchProfile && matchProfile.params) || {};
  };

  render() {
    const { credentials: { embedId, embedUrl, embedToken } = {} } = this.props;
    return (
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          embedId,
          embedUrl,
          accessToken: embedToken,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
          },
        }}
        cssClassName={"powerbi-container"}
      />
    );
  }
}

function mapStateToProps(state, initialProps) {
  const {
    match: {
      params: { id },
    },
  } = initialProps;
  return {
    id: id,
    credentials: state.reports.all[id],
  };
}

export default connect(mapStateToProps, { fetchAnalyticsReportCreds })(ReportDisplayPage);
