import React from "react";
import moment from "moment";
import LabeledField from "../common/LabeledField";

export default function PatientInfo(props) {
  const { location, admitDate, dischargeDate, billingInsurancePlan, payerName } = props;
  const information = [
    {
      label: "Location",
      value: location,
    },
    {
      label: "Admitted",
      value: moment(admitDate).format("M/DD/YYYY"),
    },
    {
      label: "Current Payer",
      value:
        payerName && billingInsurancePlan && billingInsurancePlan.careLevel
          ? `${payerName} ${billingInsurancePlan.careLevel}`
          : "",
    },
    {
      label: "Status",
      value: dischargeDate ? "Discharged" : "Current",
    },
  ];

  return (
    <>
      <h2 className="h5">
        <strong>Patient Info</strong>
      </h2>
      <div className="hpanel">
        <div className="panel-body">
          {information.map(({ label, value }) => (
            <LabeledField key={label} label={label}>
              {value}
            </LabeledField>
          ))}
        </div>
      </div>
    </>
  );
}
