import { FETCH_RATE_HISTORY, UPDATE_RATE_HISTORY } from "../actions/types";
import { normalize } from "./index";

const initialState = {
  all: {},
  byStay: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case `${FETCH_RATE_HISTORY}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_RATE_HISTORY}_FULFILLED`:
      const normalized_rates = normalize(payload.data);
      return {
        ...state,
        isLoading: false,
        all: {
          ...state.all,
          ...normalized_rates,
        },
        byStay: {
          ...state.byStay,
          [payload.stayId]: Object.keys(normalized_rates),
        },
      };
    case `${UPDATE_RATE_HISTORY}_FULFILLED`:
      return {
        ...state,
        all: {
          ...state.all,
          [payload.data.id]: payload.data,
        },
      };
    default:
      return state;
  }
}
