import React from "react";
import { connect } from "react-redux";
import { serviceNotesForIntake } from "../store/selectors";

export class ServiceNotes extends React.Component {
  render() {
    const { serviceNotes } = this.props;
    return <div style={{ whiteSpace: "pre" }}>{serviceNotes}</div>;
  }
}

function mapStateToProps(_, initialProps) {
  const { intakeId } = initialProps;
  const serviceNotesSelector = serviceNotesForIntake(intakeId);
  return (state) => ({
    serviceNotes: serviceNotesSelector(state),
  });
}

export default connect(mapStateToProps)(ServiceNotes);
