import React from "react";

export default function Modal({ title, description, children, open, onClose, footer }) {
  return open ? (
    <div className="modal-open">
      <div className="fade modal-backdrop in" />
      <div className="modal fade in" tabIndex="-1" role="dialog" style={{ display: "block" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
              {title && <h4 className="modal-title">{title}</h4>}
              {description}
            </div>
            <div className="modal-body">{children}</div>
            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

Modal.defaultProps = {
  open: false,
};
