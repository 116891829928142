import React from "react";
import moment from "moment";
import { upperFirst } from "lodash";
import Table from "../../common/Table";

const CONFIG = {
  columns: [
    {
      title: "Date",
      key: "date",
      transformer: (colVal) => moment(colVal).format("M/DD/YYYY"),
      sortValue: ({ date }) => moment(date).unix(),
      sortable: true,
      headerClassName: "col-sm-2",
    },
    {
      title: "Setting",
      key: "setting",
      transformer: (value) => formatNames(value),
      sortable: true,
      headerClassName: "col-sm-2",
    },
    {
      title: "OT",
      key: "OT",
      sortable: true,
    },
    {
      title: "PT",
      key: "PT",
      sortable: true,
    },
    {
      title: "ST",
      key: "ST",
      sortable: true,
    },
    {
      title: "Total",
      key: "total",
      sortable: true,
    },
  ],
  defaultSort: {
    column: 0,
    direction: "descending",
  },
};

const commaDelineateStrings = (values) => {
  // Make an array of strings into a comma delineated list, if they're not all strings return "".
  for (let value of values) {
    if (typeof value !== "string") return "";
  }
  return values.join(", ");
};

const formatNames = (names) => {
  if (names.constructor !== Array) return upperFirst(names);
  return names.map((n) => upperFirst(n));
};

const TherapyTable = ({ data, ...props }) => {
  return <Table config={CONFIG} data={data} isRowExpandable={true} stringTransform={commaDelineateStrings} />;
};

export default TherapyTable;
