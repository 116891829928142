import React, { Component } from "react";
import { connect } from "react-redux";
import IntakeForm from "./Form";
import { fetchIntake } from "../store/actions";
import { intakePrimaryPayerSelector } from "../store/selectors";

class EditIntake extends Component {
  componentDidMount() {
    const { fetchIntake, id } = this.props;
    fetchIntake(id);
  }

  render() {
    const { intake, intakePrimaryPayer = {} } = this.props;
    const intakeWithPrimaryPayerFullInfo = intake && {
      ...intake,
      primaryPayer: { ...intake.primaryPayer, ...intakePrimaryPayer },
    };
    return <IntakeForm {...intakeWithPrimaryPayerFullInfo} />;
  }
}

function mapStateToProps(_, ownProps) {
  const {
    match: {
      params: { id },
    },
  } = ownProps;
  return (state) => ({
    id,
    intake: state.intake.all[id],
    intakePrimaryPayer: id && intakePrimaryPayerSelector(id)(state),
  });
}

export default connect(mapStateToProps, { fetchIntake })(EditIntake);
