import React from "react";
import Table from "./Table";

export default class ExpandableTable extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }
  get expandable() {
    const { listToExpand = [] } = this.props;
    return listToExpand.length > 0;
  }
  toggle = () => this.expandable && this.setState((prevState) => ({ open: !prevState.open }));

  render() {
    const { className, listToExpand = [], config, title } = this.props;
    const { open } = this.state;
    return (
      <>
        {this.expandable && (
          <div key={"id"} className={`panel panel-default m-b-xxs ${className}`}>
            <div className="panel-heading d-flex" onClick={this.toggle}>
              <span className="flex-1-auto" style={{ textAlign: "left" }}>
                {title}
              </span>
              <button type="button" className="btn-link" onClick={this.toggle.stopPropagation}>
                {`View all (${listToExpand.length}) `}
                <i className={`fa fa-${open ? "minus" : "plus"}-square`} />
              </button>
            </div>
          </div>
        )}
        {this.expandable && open && <Table data={listToExpand} config={config} />}
      </>
    );
  }
}
