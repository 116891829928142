import { createStore, applyMiddleware, compose } from "redux";
import promiseMiddleware from "redux-promise-middleware";
import reducers from "./reducers";
import thunk from "redux-thunk";

const composeEnhancers =
  (process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancers = composeEnhancers(applyMiddleware(promiseMiddleware(), thunk));

export const store = createStore(reducers, enhancers);
