import React from "react";
import SingleDatePicker from "./SingleDatePicker";

export class DateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
  }
  handleStartChange(startDate) {
    // TODO possibly fetch more data
    let endDate = this.props.endDate;
    if (this.props.endDate && startDate && startDate.isAfter(this.props.endDate)) endDate = startDate;
    this.props.setDateFilter({
      startDate,
      endDate,
      facility: this.props.facility,
    });
  }
  handleEndChange(endDate) {
    // TODO possibly fetch more data
    this.props.setDateFilter({
      endDate,
      startDate: this.props.startDate,
      facility: this.props.facility,
    });
  }
  render() {
    return (
      <div className="flex-0-auto">
        <label className="m-r-xs">Date Range:</label>
        <SingleDatePicker
          id="startDueDate"
          date={this.props.startDate}
          onDateChange={this.handleStartChange}
          placeholder="Start Date"
          isOutsideRange={(day) => {
            if (this.props.endDate) {
              return day.isAfter(this.props.endDate, "day");
            }
          }}
          showClearDate
        />
        {" - "}
        <SingleDatePicker
          id="endDueDate"
          date={this.props.endDate}
          onDateChange={this.handleEndChange}
          placeholder="End Date"
          isOutsideRange={(day) => {
            if (this.props.startDate) {
              day.isBefore(this.props.startDate, "day");
            }
          }}
          showClearDate
        />
      </div>
    );
  }
}
