import React from "react";
import { sortBy } from "lodash";
import moment from "moment";

const DAYS_UNTIL_ARCHIVED = 14;

class ServiceItem extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }
  get expandable() {
    const { orders = [], diagnoses = [] } = this.props;
    return orders.length > 0 || diagnoses.length > 0;
  }
  toggle = () => this.expandable && this.setState((prevState) => ({ open: !prevState.open }));

  choose_color = (orders, diagnoses) => {
    for (let o of orders) {
      if (moment().diff(moment(o.date), "days") <= DAYS_UNTIL_ARCHIVED) {
        return "#7AECEB";
      }
    }
    for (let d of diagnoses) {
      if (moment().diff(moment(d.date), "days") <= DAYS_UNTIL_ARCHIVED) {
        return "#7AECEB";
      }
    }
    return "none";
  };

  render() {
    const { id, description, orders = [], diagnoses = [] } = this.props;
    const { open } = this.state;
    return (
      <div key={id} className="panel panel-default m-b-xxs">
        <div
          className="panel-heading d-flex"
          style={{ backgroundColor: this.expandable ? this.choose_color(orders, diagnoses) : "none" }}
          onClick={this.toggle}
        >
          <span className="flex-1-auto">{description}</span>
          {this.expandable && (
            <button type="button" className="btn-link" onClick={this.toggle.stopPropagation}>
              Services Delivered <i className={`fa fa-${open ? "minus" : "plus"}-square`} />
            </button>
          )}
        </div>
        {this.expandable && open && (
          <div className="panel-body">
            {orders.length > 0 && (
              <div>
                <h5 className="m-b-sm">Patient has order for:</h5>
                <ul>
                  {orders.map(({ id, description, date }) => (
                    <li className="m-b-sm" key={id}>
                      <b>{moment(date).format("M/DD/YYYY")}</b>: {description}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {diagnoses.length > 0 && (
              <div>
                <ul>
                  {diagnoses.map(({ id, description, date, code }) => (
                    <li className="m-b-sm" key={id}>
                      <b>{moment(date).format("M/DD/YYYY")}</b>: Patient presents with a diagnosis of {description}
                      <b> {code}</b>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default function ServiceSummary({ plans = [], carveOuts = [] }) {
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* TODO - Add editable mode for serice summary and link this button to it
        <span>
          <button className="btn btn-primary">Edit Services</button>
        </span> */}
      </div>
      {plans.length <= 0 && carveOuts.length <= 0 && (
        <p>
          No services are expected at this time. Use the Edit Services button above to add services for this patient.
        </p>
      )}
      <div className="row">
        {plans.length > 0 && (
          <div className={carveOuts.length > 0 ? "col-sm-7 col-md-6" : "col-xs-12"}>
            {sortBy(plans, [(plan) => plan.rank]).map((plan) => {
              return (
                <React.Fragment key={plan.id}>
                  <h4>{plan.careLevel}</h4>
                  <p>{plan.description}</p>
                  {plan.medicallyComplexCareEvents.map((mcce) => (
                    <ServiceItem key={`${mcce.id}${mcce.description}`} {...mcce} />
                  ))}
                </React.Fragment>
              );
            })}
          </div>
        )}
        {carveOuts.length > 0 && (
          <div className={plans.length > 0 ? "col-sm-5 col-md-6" : "col-xs-12"}>
            <h4>Other Billable Items</h4>
            {carveOuts.map((co) => {
              return <ServiceItem {...co} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
}
