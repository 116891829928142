import { createSelector } from "reselect";

export const translateStatus = createSelector(
  [(state) => state.alerts.statuses, (state, statusId) => statusId],
  (statuses, id) => {
    if (statuses && statuses[id]) {
      return { label: statuses[id].text, value: statuses[id].id };
    }
    return { label: "-", value: null };
  }
);

export const getStatusObjects = createSelector(
  (state) => state.alerts.statuses,
  (statuses) =>
    statuses
      ? Object.values(statuses).reduce((prev, s) => {
          return [...prev, { label: s.text, value: s.id }];
        }, [])
      : []
);

export const getCategoryObjects = createSelector(
  (state) => state.alerts.categories,
  (categories) =>
    categories
      ? Object.values(categories).reduce((prev, c) => {
          return [...prev, { label: c.name, value: c.id }];
        }, [])
      : []
);

export const getTypeValueObject = createSelector(
  (state) => state.alerts.types,
  (types) =>
    types
      ? Object.values(types).reduce((prev, t) => {
          return { ...prev, [t.name]: t.id };
        }, {})
      : {}
);
