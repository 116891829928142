import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import IntakeForm from "./Form";
class NewIntake extends Component {
  render() {
    const { newIntakeId, match: { path } = {} } = this.props;
    return (
      <Switch>
        {newIntakeId && <Redirect to={`/intakes/${newIntakeId}`} />}
        <Route exact path={path} component={IntakeForm} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  const { newIntakeId } = state.intake;
  return { newIntakeId };
}

export default connect(mapStateToProps)(NewIntake);
