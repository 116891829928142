import { FETCH_STAY_BY_PATIENT_AND_DATE, FETCH_ALERTS } from "../actions/types";

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${FETCH_STAY_BY_PATIENT_AND_DATE}_FULFILLED`:
      let { data, date, patientId } = payload;
      const patient = data.patient || {};
      return {
        ...state,
        [patientId]: {
          ...patient,
          stayIdsByDate: {
            ...patient.stayIdsByDate,
            [date]: data.id,
          },
        },
      };
    case `${FETCH_ALERTS}_FULFILLED`:
      data = payload.data;
      return {
        ...state,
        ...data
          .filter(({ patient }) => patient)
          .reduce(
            (result, alert) => ({
              ...result,
              [alert.patient.id]: { ...alert.patient, stayIdsByDate: {} },
            }),
            {}
          ),
      };
    default:
      return state;
  }
};
