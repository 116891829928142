import React, { useState } from "react";
import moment from "moment";
import { sortBy } from "lodash";
import { DateFilter } from "../common/DateFilter";
import { useFunctionData, useFunctionEvaluations } from "../api/caseManagementApi";
import LoadingIndicator from "../common/LoadingIndicator";
import Table from "../common/Table";

const config = {
  columns: [
    {
      title: "Function Date",
      key: "functionDate",
      transformer: (colVal) => moment(colVal).format("M/DD/YYYY"),
      sortValue: ({ functionDate }) => moment(functionDate).unix(),
      sortable: true,
    },
    {
      title: "Eating",
      key: "eating",
      transformer: (resp) => resp && resp.value,
      bodyClassName: "text-center",
      headerClassName: "text-center",
      hoverContent: (data) => getTooltip(data.responses),
      sortable: true,
    },
    {
      title: "Toilet Hygiene",
      key: "toiletHygiene",
      transformer: (resp) => resp && resp.value,
      bodyClassName: "text-center",
      headerClassName: "text-center",
      hoverContent: (data) => getTooltip(data.responses),
      sortable: true,
    },
    {
      title: (
        <>
          Bed Mobility:
          <br />
          Sit to Lying
        </>
      ),
      key: "sitToLying",
      transformer: (resp) => resp && resp.value,
      bodyClassName: "text-center",
      headerClassName: "text-center",
      hoverContent: (data) => getTooltip(data.responses),
      sortable: true,
    },
    {
      title: (
        <>
          Bed Mobility:
          <br />
          Lying to Sitting
        </>
      ),
      key: "lyingToSitting",
      transformer: (resp) => resp && resp.value,
      bodyClassName: "text-center",
      headerClassName: "text-center",
      hoverContent: (data) => getTooltip(data.responses),
      sortable: true,
    },
    {
      title: (
        <>
          Transferring:
          <br />
          Sit to Stand
        </>
      ),
      key: "sitToStand",
      transformer: (resp) => resp && resp.value,
      bodyClassName: "text-center",
      headerClassName: "text-center",
      hoverContent: (data) => getTooltip(data.responses),
      sortable: true,
    },
    {
      title: (
        <>
          Transferring:
          <br />
          Bed to Chair
        </>
      ),
      key: "bedToChair",
      transformer: (resp) => resp && resp.value,
      bodyClassName: "text-center",
      headerClassName: "text-center",
      hoverContent: (data) => getTooltip(data.responses),
      sortable: true,
    },
    {
      title: (
        <>
          Transferring:
          <br />
          Toilet Transfer
        </>
      ),
      key: "toiletTransfer",
      transformer: (resp) => resp && resp.value,
      bodyClassName: "text-center",
      headerClassName: "text-center",
      hoverContent: (data) => getTooltip(data.responses),
      sortable: true,
    },
    {
      title: "Function Index",
      key: "functionIndex",
      bodyClassName: "text-center",
      headerClassName: "text-center",
      sortable: true,
    },
  ],
  defaultSort: {
    column: 0,
    direction: "descending",
  },
};

const getTooltip = (data) => {
  if (data) {
    return (
      <div>
        <p align="left">Responses include:</p>
        <p align="left">
          {sortBy(data)
            .map((x) => String(x).padStart(2, "0"))
            .join(", ")}
        </p>
      </div>
    );
  }
};

export const FunctionList = (props) => {
  const { stay } = props;

  const [filters, setFilters] = useState({ startDate: null, endDate: null });

  const functionDataQuery = useFunctionData(stay.id);
  const functionEvaluationQuery = useFunctionEvaluations(stay.id);

  const handleDateFilterChange = (selectedValue) => {
    setFilters({ startDate: selectedValue.startDate, endDate: selectedValue.endDate });
  };

  if (functionDataQuery.isError || functionEvaluationQuery.isError) {
    return <div style={{ marginTop: 20 }}>Error fetching function scores.</div>;
  }

  if (functionDataQuery.isLoading || functionEvaluationQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (functionDataQuery.data && functionEvaluationQuery.data) {
    let data = getTableData(functionDataQuery.data, functionEvaluationQuery.data);
    return (
      <>
        <div className="d-flex justify-end p-xs">
          <DateFilter endDate={filters.endDate} startDate={filters.startDate} setDateFilter={handleDateFilterChange} />
        </div>
        <Table data={data} config={config} />
      </>
    );
  }
  return <div style={{ marginTop: 20 }}>No function responses found for this patient.</div>;
};

const getTableData = (functionViewData, functionEvaluations) => {
  /*
  Data is structure as follows:

  functionViewData: [
    {
      functionDate: date,
      functionIndex: index,
      [category]: [score],
    }, ..
  ]

  functionEvaluations: {
    [date]: [
      {
        function details including...
        category: [category],
        response: [response],
      }, ..
    ]
  }
  */
  const functionData = [];
  functionViewData.forEach((dailyData) => {
    let tempData = {};
    Object.entries(dailyData).forEach(([key, value]) => {
      if (Object.values(categoryMapping).includes(key)) {
        tempData[key] = {
          value: value ? value : "-",
          responses: [],
        };
      } else {
        tempData[key] = value;
      }
    });
    functionEvaluations[tempData.functionDate].forEach((evaluationObject) => {
      if (categoryMapping[evaluationObject.category]) {
        tempData[categoryMapping[evaluationObject.category]] &&
          tempData[categoryMapping[evaluationObject.category]].responses.push(evaluationObject.response);
      }
    });
    functionData.push(tempData);
  });
  return functionData;
};

const categoryMapping = {
  Eating: "eating",
  "Toileting hygiene": "toiletHygiene",
  "Sit to lying": "sitToLying",
  "Lying to sitting on side of bed": "lyingToSitting",
  "Sit to stand": "sitToStand",
  "Chair/bed-to-chair transfer": "bedToChair",
  "Toilet transfer": "toiletTransfer",
};
