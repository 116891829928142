import React from "react";

export function IcdOptionsInfo({ diagnosesShown, diagnosesTotal }) {
  return (
    <div className="bg-light p-xs m-b-n-xs">
      {diagnosesShown} of {diagnosesTotal} total
    </div>
  );
}

export default IcdOptionsInfo;
