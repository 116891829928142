import React from "react";
import { connect } from "react-redux";
import Table from "../common/Table";
import ExpandableTable from "../common/ExpandableTable";
import { fetchAllIntakeMeds } from "../store/actions";
import {
  highCostThreshold,
  createWithCostMedicationsSelector,
  createWithoutCostMedicationsSelector,
} from "../store/selectors";

export const defaultConfig = {
  columns: [
    {
      title: "Medication Name",
      key: "name",
      sortable: true,
    },
    {
      title: "Route",
      key: "route",
      bodyClassName: "text-right",
      headerClassName: "text-right",
      sortable: true,
    },
    {
      title: "Unit Cost",
      key: "unitCost",
      transformer: (val) => val && `$${val.toFixed(2)}`,
      bodyClassName: "text-right",
      headerClassName: "text-right",
      sortable: true,
      sortValue: ({ unitCost }) => unitCost || 0,
    },
    {
      title: "Quantity",
      key: "quantity",
      bodyClassName: "text-right",
      headerClassName: "text-right",
      sortable: true,
      sortValue: ({ quantity }) => quantity || 0,
    },
    {
      title: "Total Cost",
      transformer: ({ unitCost, quantity }) => unitCost && quantity && `$${(unitCost * quantity).toFixed(2)}`,
      bodyClassName: "text-right",
      headerClassName: "text-right",
      sortable: true,
      sortValue: ({ unitCost, quantity }) => (unitCost && quantity && unitCost * quantity) || 0,
    },
  ],
  defaultSort: {
    column: "Total Cost",
    direction: "descending",
  },
};

const rowStyler = ({ unitCost, quantity }) => {
  return unitCost && quantity && unitCost * quantity > highCostThreshold && { rowClassName: "text-danger" };
};

export class Medications extends React.Component {
  render() {
    const { medicationsWithCost = [], medicationsWithoutCost = [], config = defaultConfig } = this.props;
    return (
      <>
        <Table data={medicationsWithCost} config={config} rowStyler={rowStyler} />
        <ExpandableTable
          title="Medication Costs Not Found"
          listToExpand={medicationsWithoutCost}
          config={config}
          className={medicationsWithCost.length > 0 ? "m-t-n-md" : ""}
        />
      </>
    );
  }
}

function mapStateToProps(_, initialProps) {
  const { intakeId } = initialProps;
  return (state) => ({
    intake: state.intake.all[intakeId],
    medicationsWithCost: createWithCostMedicationsSelector(intakeId)(state),
    medicationsWithoutCost: createWithoutCostMedicationsSelector(intakeId)(state),
  });
}

export default connect(mapStateToProps, { fetchAllIntakeMeds })(Medications);
