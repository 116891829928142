import ServiceSummary from "../common/ServiceSummary";
import { connect } from "react-redux";

function mapStateToProps(state, initialProps) {
  return (state) => {
    const { plans = [], billableItems = [] } = state.intake.all[initialProps.intakeId].primaryPayer;
    return {
      plans: plans.map((plan) => {
        const { mcces, ...rest } = plan;
        return {
          id: plan.careLevel,
          medicallyComplexCareEvents: mcces.filter((mcce) => mcce.checked),
          ...rest,
        };
      }),
      carveOuts: billableItems.filter((item) => item.checked),
    };
  };
}

export default connect(mapStateToProps)(ServiceSummary);
