import React from "react";
import LabeledField from "../common/LabeledField";
import { capitalize } from "lodash";

export default function PatientInfo({
  facilityName,
  primaryDiagnosis,
  primaryPayer,
  secondaryPayer,
  referralSource,
  insuranceNumber,
  insuranceAuthorizationNumber,
  status,
}) {
  const information = [
    {
      label: "Facility",
      value: facilityName,
    },
    {
      label: "Primary Payer",
      value: primaryPayer.displayName,
    },
    {
      label: "Secondary Payer",
      value: secondaryPayer,
    },
    {
      label: "Diagnosis",
      value: primaryDiagnosis && `${primaryDiagnosis.description} (${primaryDiagnosis.code})`,
    },
    {
      label: "Referral Source",
      value: referralSource,
    },
    {
      label: "Insurance Number",
      value: insuranceNumber,
    },
    {
      label: "Authorization Number",
      value: insuranceAuthorizationNumber,
    },
    {
      label: "Status",
      value: capitalize(status),
    },
  ];
  return (
    <>
      <h2 className="h5">
        <strong>Basic Information</strong>
      </h2>
      <div className="hpanel">
        <div className="panel-body">
          {information.map(({ label, value }) => (
            <LabeledField key={label} label={label}>
              {value}
            </LabeledField>
          ))}
        </div>
      </div>
    </>
  );
}
