import React from "react";
import { connect } from "react-redux";
import Table from "../common/Table";
import ExpandableTable from "../common/ExpandableTable";
import { fetchAllIntakeConditions, fetchAllIntakeSymptoms } from "../store/actions";
import DeleteConditionButton from "./DeleteConditionButton";
import { isToggleTurnedOn } from "../common/FeatureToggle";
import {
  createReturnToProviderConditionsSelector,
  createNotFoundConditionsSelector,
  intakeSymptomsSelector,
} from "../store/selectors";

const columns = [
  {
    title: "Description",
    key: "termName",
    bodyClassName: "text-left",
    headerClassName: "text-left",
    sortable: true,
  },
  {
    title: "Projected ICD10",
    key: "icd10Code",
    bodyClassName: "text-right",
    headerClassName: "text-right",
    sortable: true,
  },
  {
    title: "Clinical Category",
    key: "ptotCode",
    transformer: (ptotCode) => (
      <div className={`${ptotCode === "Return to Provider" ? "text-danger" : ""}`}>{ptotCode}</div>
    ),
    bodyClassName: "text-right",
    headerClassName: "text-right",
    sortable: true,
  },
  {
    title: "SLP",
    key: "slp",
    bodyClassName: "text-right",
    headerClassName: "text-right",
    sortable: true,
  },
  {
    title: "NTA",
    key: "nta",
    bodyClassName: "text-right",
    headerClassName: "text-right",
    sortable: true,
  },
];
if (isToggleTurnedOn("DELETE_CONDITION")) {
  columns.push({
    transformer: (condition) => {
      return <DeleteConditionButton condition={condition} />;
    },
    bodyClassName: "text-right",
  });
}

export const defaultConfig = {
  columns: columns,
  defaultSort: {
    column: 0,
    direction: "ascending",
  },
};

export class Conditions extends React.Component {
  render() {
    const {
      config = defaultConfig,
      conditions = [],
      symptoms = [],
      returnToProviderConditions = [],
      notFoundConditions = [],
    } = this.props;
    return (
      <>
        <Table data={conditions} config={config} />
        <ExpandableTable
          title="Return to Provider"
          listToExpand={returnToProviderConditions}
          config={config}
          className={conditions.length > 0 ? "m-t-n-md" : ""}
        />
        <ExpandableTable title="ICD 10 Not Found" listToExpand={notFoundConditions} config={config} />
        <ExpandableTable title="Symptom" listToExpand={symptoms} config={config} />
      </>
    );
  }
}

function mapStateToProps(_, initialProps) {
  const { intakeId, intakeConditionsSelector } = initialProps;
  return (state) => ({
    intake: state.intake.all[intakeId],
    conditions: intakeConditionsSelector(intakeId)(state),
    symptoms: intakeSymptomsSelector(intakeId)(state),
    returnToProviderConditions: createReturnToProviderConditionsSelector(intakeId)(state),
    notFoundConditions: createNotFoundConditionsSelector(intakeId)(state),
  });
}

export default connect(mapStateToProps, { fetchAllIntakeConditions, fetchAllIntakeSymptoms })(Conditions);
