import React from "react";
import PropTypes from "prop-types";
import { sortBy as _sortBy } from "lodash";
import Fuse from "fuse.js";
import TableRow from "./TableRow";
import TableExpandableRow from "./TableExpandableRow";
import { connect } from "react-redux";
import { withMixpanel } from "react-mixpanel-browser";
import LoadingIndicator from "./LoadingIndicator";
import Select from "react-select";

const propTypes = {
  /**
   * Array of objects containing the data to diplay in the table
   */
  data: PropTypes.array,
  /**
   * Loading state of the table
   */
  isLoading: PropTypes.bool,
  /**
   * Configuration for the table including headers, sorting, and search settings
   */
  config: PropTypes.shape({
    /**
     * The array of columns in the table
     */
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        /** The display title for this column */
        title: PropTypes.string,
        /** The key in the data object who value will be used in the table (optional) */
        key: PropTypes.string,
        /**
         * A function to transform the data value. If a key was given, the parameter to the function will be the key.
         * If a key was not given, the parameter of the function will be the entire object for the row.
         */
        transformer: PropTypes.func,
        /** Whether or not this column is sortable */
        sortable: PropTypes.bool,
        /** The key of the data object to use for sorting. Use this if no key prop was given or the sort value differs from the display key */
        sortKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        /**
         * Similar to the transformer, the sortValue prop is used to transform the data into a sortable value.
         * For example, transform a date into a unix timestamp for proper date sorting.
         * Note that this function will always receive the full data object as a param.
         * */
        sortValue: PropTypes.func,
        bodyClassName: PropTypes.string,
        hoverContent: PropTypes.func,
      })
    ),
    /**
     * Search settings for the table (optional)
     * When no search settings are given, then no search input appears.
     * There are additional search options available that are not defined in the shape. See fusejs.io for all search options available.
     */
    search: PropTypes.shape({
      /** Specify the keys of the data to search on. This *must* be specified in order to enable searching. */
      keys: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            name: PropTypes.string,
            weight: PropTypes.number,
            placeholder: PropTypes.string,
            searchInputStyle: PropTypes.object,
            searchCounterName: PropTypes.string,
          }),
        ])
      ),
    }),
    /** The default sort tells the table to start in a sorted state */
    defaultSort: PropTypes.shape({
      /** The column to sort by on initial render */
      column: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      /** The direction to sort the column on initial render */
      direction: PropTypes.oneOf(["ascending", "descending"]),
    }),
    pagination: PropTypes.shape({
      enabled: PropTypes.bool,
      page: PropTypes.number,
      pageSize: PropTypes.number,
      pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
      totalCount: PropTypes.number,
      setPage: PropTypes.func,
      setPageSize: PropTypes.func,
    }),
  }),
  /**
   * This function allows adding additonal props per row based on the row data.
   * The function takes a param, data, that is the row object from the data set given
   * and should return a set of props to apply to the tr element.
   * Typically this is used to apply `className` and `style` per row, but may be used to apply other props as needed.
   */
  rowStyler: PropTypes.func,
  headerContent: PropTypes.node,
  searchContent: PropTypes.node,
  headerStyle: PropTypes.object,
  stringTransform: PropTypes.func,
  numberTransform: PropTypes.func,
};

/**
 * A generic data table that enables client-side searching and sorting
 */
export class Table extends React.Component {
  constructor(props) {
    super(props);
    const { config: { columns = [], defaultSort = {} } = {} } = this.props;
    const sort = { ...defaultSort };
    // if a column title was given then change it to the column index
    if (typeof sort.column === "string") {
      sort.column = columns.map((column) => column.title).indexOf(sort.column);
    }
    this.state = {
      sort: sort,
      search: "",
    };
    this.sortBy = this.sortBy.bind(this);
    this.sort = this.sort.bind(this);
    this.search = this.search.bind(this);
  }
  handleSearchBlur() {
    const { user, mixpanel } = this.props;
    const { category } = this.props.config;
    const { search } = this.state;

    mixpanel.track(`${category} filter added`, {
      email: user.email,
      Search: search,
    });
  }

  filterExpandableRows = (row) => {
    for (let col in row) {
      if (row[col].constructor === Array) return true;
    }
    return false;
  };

  sort(data = []) {
    const { config: { columns = [] } = {} } = this.props;
    const {
      sort: { column, direction },
    } = this.state;

    // Determine what to sort by, sortValue preferred over sortKey which is preferred over key
    const { key, sortValue, sortKey } = columns[column] || {};
    const sorter = sortValue ? sortValue : sortKey || key;

    // Only sort when given valid sorting information
    if (!direction || (data.length > 0 && column >= 0 && column < data[0].length) || !sorter) return data;

    const sorted = _sortBy(data, sorter);
    return direction === "descending" ? sorted.reverse() : sorted;
  }

  sortBy(column) {
    this.setState((state) => {
      return {
        sort: {
          column,
          direction: state.sort.column === column && state.sort.direction === "ascending" ? "descending" : "ascending",
        },
      };
    });
  }

  search(data = []) {
    const { search: searchTerm } = this.state;
    const { config: { search: searchOptions } = {} } = this.props;

    if (!searchTerm || !searchOptions || !searchOptions.keys) return data;
    return new Fuse(data, {
      threshold: 0.0,
      tokenize: true,
      matchAllTokens: true,
      ...searchOptions,
    }).search(searchTerm);
  }

  render() {
    const {
      data = [],
      config: {
        columns = [],
        search: { keys: searchable, placeholder, searchInputStyle, searchCounterName } = {},
        pagination = {},
      } = {},
      rowStyler,
      headerContent,
      searchContent,
      headerStyle,
      isRowExpandable,
      stringTransform = undefined,
      numberTransform = undefined,
      isLoading = false,
    } = this.props;
    const {
      sort: { column, direction },
      search,
    } = this.state;
    const tableData = this.sort(this.search(data));

    return (
      <>
        {(headerContent || searchable) && (
          <div id="tableHeaderContent" className="d-flex" style={headerStyle || { flexDirection: "row-reverse" }}>
            {searchable && (
              <div
                id="tableSearchFilter"
                className="d-flex flex-0-auto align-f-start"
                style={{ flexDirection: "row-reverse" }}
              >
                <div className="flex-0-auto">{searchContent}</div>
                <div
                  className="input-group input-group-sm p-xs search-row"
                  style={searchInputStyle || { flexBasis: "300px", maxWidth: "300px" }}
                >
                  <span className="input-group-addon">
                    <i className={"fa fa-search"} />
                  </span>
                  <input
                    type="text"
                    placeholder={placeholder}
                    className={"form-control"}
                    style={{ zIndex: 0 }}
                    value={search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                    onBlur={() => this.handleSearchBlur()}
                  />
                </div>
              </div>
            )}
            <div className="flex-1-auto">{headerContent}</div>
          </div>
        )}
        {isLoading && <LoadingIndicator />}
        {!isLoading && searchCounterName && getCounterHeader(searchCounterName, pagination, tableData)}
        {!isLoading && tableData.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  {columns.map(({ title, sortable, headerClassName = "" }, i) => {
                    return (
                      <th
                        key={`${title}${i}`}
                        onClick={sortable ? () => this.sortBy(i) : null}
                        className={`${sortable ? "clickable" : ""} ${headerClassName}`}
                      >
                        {title}{" "}
                        {sortable && (
                          <i
                            className={`fa fa-sort${column === i && "-"}${direction === "ascending" ? "asc" : "desc"}`}
                          />
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData.map((data, i) => {
                  const rowProps = (rowStyler && typeof rowStyler === "function" && rowStyler(data)) || {};
                  const { rowClassName } = rowProps;
                  const rowData = {
                    key: data && data.id ? data.id : data.date || `${data}${i}`,
                    data: data,
                    columns: columns,
                    rowClassName: rowClassName,
                    stringTransform: stringTransform,
                    numberTransform: numberTransform,
                  };
                  return isRowExpandable && this.filterExpandableRows(rowData.data) ? (
                    <TableExpandableRow {...rowData} />
                  ) : (
                    <TableRow {...rowData} />
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {!isLoading && pagination.enabled && getPages(pagination)}
      </>
    );
  }
}

Table.propTypes = propTypes;

function getCounterHeader(searchCounterName, pagination, tableData) {
  if (pagination && pagination.enabled) {
    let first = 0;
    if (tableData.length) {
      first = pagination.page * pagination.pageSize === 0 ? 1 : pagination.page * pagination.pageSize;
    }
    const last =
      pagination.page * pagination.pageSize + pagination.pageSize > pagination.totalCount
        ? pagination.totalCount
        : pagination.page * pagination.pageSize + pagination.pageSize;
    return (
      <p className="bg-light m-r-sm m-l-sm p-xxs" style={{ textAlign: "center" }}>
        {first}-{last} of {pagination.totalCount} {`${searchCounterName}${tableData.length !== 1 ? "s" : ""}`}
      </p>
    );
  } else {
    return (
      <p className="bg-light m-r-sm m-l-sm p-xxs" style={{ textAlign: "center" }}>{`${
        tableData.length
      } ${searchCounterName}${tableData.length !== 1 ? "s" : ""}`}</p>
    );
  }
}

function getPages(pagination) {
  const { page, pageSize, pageSizeOptions, totalCount, setPage, setPageSize } = pagination;
  const numberOfPages = Math.ceil(totalCount / pageSize);
  const pageSizes = pageSizeOptions.map((x) => ({ label: x, value: x }));
  const pageArray = [...Array(numberOfPages).keys()].map((x) => ({ label: x + 1, value: x }));

  return (
    <div className="d-flex align-baseline justify-center p-xxs" style={{ gap: "8px" }}>
      <div className="d-flex align-baseline">
        <button className="m-xs btn btn-primary btn-xs" disabled={page === 0} onClick={() => setPage(0)}>
          {"<<"}
        </button>
        <button className="m-xs btn btn-primary btn-xs" disabled={page === 0} onClick={() => setPage((x) => x - 1)}>
          {"<"}
        </button>
        <span>
          Page {page + 1} of {numberOfPages}
        </span>
        <button
          className="m-xs btn btn-primary btn-xs"
          disabled={page === numberOfPages - 1}
          onClick={() => setPage((x) => x + 1)}
        >
          {">"}
        </button>
        <button
          className="m-xs btn btn-primary btn-xs"
          disabled={page === numberOfPages - 1}
          onClick={() => setPage(numberOfPages - 1)}
        >
          {">>"}
        </button>
      </div>
      <div className="d-flex align-baseline">
        <label className="control-label flex-0-auto w-xs m-r-xs">Rows per page:</label>
        <Select
          name="pageSizeSelect"
          placeholder="Page Size"
          value={pageSizes.find((x) => x.value === pageSize)}
          options={pageSizes}
          onChange={(x) => setPageSize(x.value)}
          styles={{
            input: (provided) => ({
              ...provided,
              width: "30px",
            }),
          }}
        />
      </div>
      {numberOfPages > 1 && (
        <div className="d-flex align-baseline">
          <label className="control-label flex-0-auto w-xs m-r-xs">Jump to page:</label>
          <Select
            name="jumpToPage"
            placeholder="Page"
            value={pageArray.find((x) => x.value === page)}
            options={pageArray}
            onChange={(x) => setPage(x.value)}
            styles={{
              input: (provided) => ({
                ...provided,
                width: "30px",
              }),
            }}
          />
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const {
    alerts: { categories, statuses, types },
  } = state;
  return {
    categories,
    statuses,
    types,
    user: state.user,
  };
}

export default connect(mapStateToProps)(withMixpanel(Table));
