import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import PatientPage from "./PatientPage";
import PatientsPage from "./PatientsPage";
import PatientAndDateRedirect from "./PatientAndDateRedirect";
import { withMixpanel } from "react-mixpanel-browser";
import { fetchFacilities, setFacility } from "./store/actions";
import Table from "./common/Table";
import FacilityLink from "./common/FacilityLink";
import LoadingIndicator from "./common/LoadingIndicator";

export class FacilityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      facility: {
        id: localStorage.getItem("facilityId"),
        rdsId: localStorage.getItem("facilityRdsId"),
        rugSystemId: localStorage.getItem("facilityRugSystemId"),
        name: localStorage.getItem("facilityName"),
      },
    };
  }

  componentDidMount() {
    const { fetchFacilities, mixpanel, user: { email } = {} } = this.props;
    typeof fetchFacilities === "function" && fetchFacilities();
    mixpanel && mixpanel.track("visit facility list", { email: email });
  }

  handleFacilityChange = (facility) => {
    this.props.setFacility(facility);
    this.setState((state) => ({
      ...state,
      facility: facility,
    }));

    localStorage.setItem("facilityId", facility.id);
    localStorage.setItem("facilityName", facility.name);
    localStorage.setItem("facilityRdsId", facility.rdsId);
    localStorage.setItem("facilityRugSystemId", facility.rugSystemId);
  };

  render() {
    const { match: { path } = {} } = this.props;
    if (this.props.isLoading) {
      return <LoadingIndicator />;
    }
    return (
      <Switch>
        <Route
          exact
          path={path}
          component={() => {
            return (
              <>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }} className="m-b-sm">
                  <h1 className="h2 text-primary" style={{ flex: "1 0 auto" }}>
                    Please Select a Facility to View Patients
                  </h1>
                </div>
                <div className="hpanel">
                  <div className="panel-body no-padding">
                    <Table
                      config={{
                        columns: [
                          {
                            title: "Facility",
                            transformer: (data) => {
                              return (
                                <FacilityLink facilityRdsId={data.rdsId} onFacilityChange={this.handleFacilityChange} />
                              );
                            },
                            sortable: true,
                            sortKey: "name",
                          },
                        ],
                        defaultSort: {
                          column: 0,
                          direction: "ascending",
                        },
                      }}
                      data={Object.entries(this.props.facilities).map((facility) => {
                        return facility[1];
                      })}
                    />
                  </div>
                </div>
              </>
            );
          }}
        />
        <Route path={"/patients/:id([0-9]+)"} component={PatientPage} />
        <Route
          path={"/patients/facility/:id([0-9]+)"}
          component={() => {
            return <PatientsPage {...this.props} facility={this.state.facility} />;
          }}
        />
        <Route path={"/patients/redirect/:patientId([0-9]*)"} component={PatientAndDateRedirect} />
      </Switch>
    );
  }
}

FacilityPage.propTypes = {
  stays: PropTypes.array,
  payers: PropTypes.array,
  locations: PropTypes.array,
};

function mapStateToProps(state) {
  const { facilities, user = {} } = state;
  return {
    facility: facilities.facility,
    facilities: facilities.all,
    facilitiesByRdsId: facilities.byRdsId,
    isLoading: facilities.isLoading,
    userEmail: user.email,
  };
}

export default connect(mapStateToProps, { fetchFacilities, setFacility })(withMixpanel(FacilityPage));
