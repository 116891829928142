import React, { useState } from "react";
import ServiceSummary from "../common/ServiceSummary";

export default function ServiceSummaryExpandable({ payer, plans, carveOuts }) {
  const [isServicesExpanded, toggleServicesExpanded] = useState(false);
  return (
    <>
      <div
        className="d-flex align-center clickable"
        onClick={() => {
          toggleServicesExpanded(!isServicesExpanded);
        }}
      >
        <i className={`fa fa-${isServicesExpanded ? "minus" : "plus"}-square fa-lg m-r-xs`} />
        <h3>{payer && payer.displayName}</h3>
      </div>
      {isServicesExpanded && <ServiceSummary plans={plans} carveOuts={carveOuts} />}
    </>
  );
}
