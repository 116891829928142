import { FETCH_NURSE_NOTES } from "../actions/types";
import { normalize } from "./index";

const initialState = {
  all: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${FETCH_NURSE_NOTES}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_NURSE_NOTES}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        all: {
          ...state.all,
          ...normalize(action.payload.data),
        },
      };
    default:
      return state;
  }
}
