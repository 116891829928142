import React from "react";

const ALERT_TYPES = [
  {
    name: "Reimbursement",
    color: "#019999",
  },
  {
    name: "Cost",
    color: "#004859",
  },
];

export default function AlertsSummary({ alerts = [], handleFilterChange, typeValueObj = {} }) {
  return (
    <div className="row">
      {ALERT_TYPES.map((type) => {
        const count = alerts.filter((alert) => alert.type_id === typeValueObj[type.name]).length;
        return (
          <div
            className="clickable col-sm-6"
            key={type.name}
            onClick={() => handleFilterChange(typeValueObj[type.name])}
          >
            <div
              className="text-center p-xs"
              style={{
                color: "white",
                backgroundColor: type.color,
              }}
            >
              <h1>{count}</h1>
              <h4>
                {type.name} Alert{count !== 1 && "s"}
              </h4>
            </div>
          </div>
        );
      })}
    </div>
  );
}
