import React, { useEffect, useState } from "react";
import ServiceSummary from "../common/ServiceSummary";
import ServiceSummaryExpandable from "../common/ServiceSummaryExpandable";
import { useDiagnosesForStay, useOrdersForStay, usePayers } from "../api/caseManagementApi";
import LoadingIndicator from "../common/LoadingIndicator";

export const StayServiceSummary = (props) => {
  const { stay, payer, insurancePlans, patientPayers, isLoading } = props;
  const [payers, setPayers] = useState(undefined);

  const payersQuery = usePayers(patientPayers);
  const diagnosesQuery = useDiagnosesForStay(stay.id);
  const ordersQuery = useOrdersForStay(stay.id);

  const payersFetched = payersQuery.every((x) => x.isSuccess);

  useEffect(() => {
    if (payersFetched) {
      const data = payersQuery.flatMap((x) => x.data);
      setPayers(data);
    }
  }, [payersFetched]);

  if (isLoading || diagnosesQuery.isLoading || ordersQuery.isLoading || payersQuery.some((x) => x.isLoading)) {
    return <LoadingIndicator />;
  }

  let serviceSummaries = {};
  if (payer && payers && insurancePlans) {
    serviceSummaries = getServiceSummaries(payer.id, payers, insurancePlans, ordersQuery.data, diagnosesQuery.data);
  }

  const { current, old } = serviceSummaries;

  if (!payer) {
    return <div> No payer information to show.</div>;
  }

  return current ? (
    <>
      <h3 className="m-r-sm">Active Payer - {payer && payer.displayName}</h3>
      <ServiceSummary plans={current.plans} carveOuts={current.carveOuts} />
      {old.length > 0 && (
        <>
          <h3 className="m-r-sm">Inactive Payers</h3>
          {old.map((serviceSummary, i) => {
            const { payer: { id } = {} } = serviceSummary;
            return <ServiceSummaryExpandable key={id || `summary${i}`} {...serviceSummary} />;
          })}
        </>
      )}
    </>
  ) : (
    <div> No payer information to show.</div>
  );
};

const getServiceSummaries = (currentPayerId, payers, insurancePlans, orders = [], diagnoses = []) => {
  const payerIds = payers.map((x) => x.id);
  const serviceSummaries = { old: [] };

  payerIds.forEach((payerId) => {
    const payer = payers.find((x) => x.id === payerId);
    const payerPlans = Object.values(insurancePlans.filter((x) => x.payerId === payerId) || {}).filter(
      (plan) => plan.rank
    );
    let plans = [];
    if (payerPlans) {
      const ordersByMcce = {};
      orders.forEach((order) =>
        order.mcceIds.forEach((mcceId) => (ordersByMcce[mcceId] = [...(ordersByMcce[mcceId] || []), order]))
      );
      const diagnosesByMcce = {};
      diagnoses.forEach((diag) =>
        diag.mcceDescriptions.forEach(
          (mcceDesc) => (diagnosesByMcce[mcceDesc] = [...(diagnosesByMcce[mcceDesc] || []), diag])
        )
      );
      plans = payerPlans.map((plan) => ({
        ...plan,
        medicallyComplexCareEvents: plan.medicallyComplexCareEvents.map((mcce) => ({
          ...mcce,
          orders: (ordersByMcce[mcce.id] || []).sort(({ date: dateA }, { date: dateB }) => {
            if (dateA < dateB) {
              return 1;
            }
            if (dateA > dateB) {
              return -1;
            }
            return 0;
          }),
          diagnoses: (diagnosesByMcce[mcce.description] || []).sort(({ date: dateA }, { date: dateB }) => {
            if (dateA < dateB) {
              return 1;
            }
            if (dateA > dateB) {
              return -1;
            }
            return 0;
          }),
        })),
      }));
    }
    if (payerId === currentPayerId) {
      serviceSummaries.current = { payer, plans, carveOuts: [] };
    } else {
      serviceSummaries.old.push({ payer, plans, carveOuts: [] });
    }
  });

  return serviceSummaries;
};
