import React from "react";

export function IcdOptionsMenu({ fetchMaxIcdList, query }) {
  return (
    <button className="btn btn-link border-top w-100 p-xxs" onClick={() => fetchMaxIcdList(query, "maxList")}>
      See more items
    </button>
  );
}

export default IcdOptionsMenu;
