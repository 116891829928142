import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchStayByPatientIdAndDate } from "./store/actions";
import Panel from "./common/Panel";
import { useFullName } from "./utilities";
import { Redirect } from "react-router";

class PatientAndDateRedirect extends Component {
  componentDidMount() {
    const { patientId, date, stayId, fetchStayByPatientIdAndDate } = this.props;

    !stayId && typeof fetchStayByPatientIdAndDate === "function" && fetchStayByPatientIdAndDate(patientId, date);
  }

  render() {
    const { patient, stayId } = this.props;
    return (
      <>
        {patient && (
          <Panel style={{ margin: "-16px -16px 0 -16px" }}>
            <h1>{useFullName(patient)}</h1>
          </Panel>
        )}
        {stayId ? <Redirect to={`/patients/${stayId}`} /> : <h3>Loading stay information...</h3>}
      </>
    );
  }
}

function mapStateToProps(_, initialProps) {
  const patientId = initialProps.match.params.patientId;
  const date = new URLSearchParams(initialProps.location.search).get("date");
  return (state) => {
    const patient = state.patients[patientId];
    return {
      patientId,
      patient,
      date,
      stayId: patient && patient.stayIdsByDate[date],
    };
  };
}

export default connect(mapStateToProps, { fetchStayByPatientIdAndDate })(PatientAndDateRedirect);
