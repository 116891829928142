import { Auth } from "aws-amplify";

const custom_header = async () => {
  return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() };
};

export const config = {
  Auth: {
    region: process.env.REACT_APP_USER_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "CaseManagementApi",
        endpoint: process.env.REACT_APP_API_BASE_URL,
        custom_header,
      },
      {
        name: "OrcaApi",
        endpoint: process.env.REACT_APP_ORCA_BASE_URL,
        custom_header,
      },
    ],
  },
  Analytics: {
    autoSessionRecord: true,
    AWSPinpoint: {
      appId: process.env.REACT_APP_ANALYTICS_APP_ID,
      region: "us-east-1",
    },
  },
};
