import { FETCH_FACILITIES, SET_FACILITY } from "../actions/types";
import { normalize } from "./index";

const initialState = {
  all: {},
  isLoading: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case `${FETCH_FACILITIES}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_FACILITIES}_FULFILLED`:
      return {
        ...state,
        all: {
          ...state.all,
          ...normalize(payload.data),
        },
        byRdsId: {
          ...state.byRdsId,
          ...normalize(payload.data, "rdsId"),
        },
        isLoading: false,
      };
    case `${SET_FACILITY}`:
      return {
        ...state,
        facility: payload,
      };
    default:
      return state;
  }
}
