import React, { Component } from "react";
import { connect } from "react-redux";
import { documentsForIntake } from "../store/selectors";
import Table from "../common/Table";
import { API } from "aws-amplify";
import { apiName } from "../store/actions/orca";
import FileInput from "../common/FileInput";
import { updateIntake, clearNewIntake, clearIntakeFile } from "../store/actions";
import { uploadFile } from "../utilities";
import { withMixpanel } from "react-mixpanel-browser";

const downloadFile = async ({ s3Key, name, facilityId }) => {
  const { downloadUrl } = await API.post(apiName, "/download-referral-url", {
    body: { facilityId, objectKey: s3Key },
  });
  if (!downloadUrl) {
    return;
  }
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = name;
  document.body.appendChild(link);
  link.click();
};

const deleteIntakeFile = async ({ s3Key, intakeId, intakeFiles, updateIntake, clearNewIntake, clearIntakeFile }) => {
  const data = {
    files: intakeFiles.filter((filteredFile) => filteredFile.s3Key !== s3Key),
  };
  await updateIntake(intakeId, data);
  clearIntakeFile(intakeId, s3Key);
  clearNewIntake();
};

const config = {
  columns: [
    {
      title: "File Name",
      transformer: (file) => {
        return (
          <button
            className="btn btn-link"
            onClick={async (evt) => {
              await downloadFile(file);
            }}
          >
            {file.name}
          </button>
        );
      },
    },
    {
      title: "Contents",
      key: "types",
      transformer: (labels = []) =>
        labels.map((label) => {
          return (
            <span key={label} className="label label-default m-r-xs">
              {label}
            </span>
          );
        }),
    },
    {
      transformer: (file) => {
        return (
          <>
            <i
              className="fa fa-2x fa-download text-primary m-l clickable"
              aria-label="Download Document"
              onClick={async (evt) => {
                await downloadFile(file);
              }}
            />
            <i
              className="fa fa-2x fa-times-circle text-danger m-l clickable"
              aria-label="Delete Document"
              onClick={() => deleteIntakeFile(file)}
            />
          </>
        );
      },
      bodyClassName: "text-right",
    },
  ],
};

export class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      isFileLoading: false,
    };
  }

  setAndUploadFiles = (additionalFiles) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [prevState.files]: additionalFiles,
        isFileLoading: true,
      }),
      () => {
        this.uploadAdditionalFiles(additionalFiles);
      }
    );
  };

  uploadAdditionalFiles = async (files) => {
    const {
      facilityId,
      intakeId,
      intakeFiles,
      clearNewIntake,
      updateIntake,
      mixpanel,
      user: { email } = {},
    } = this.props;
    try {
      let additionalIntakeFiles = [];
      if (files.length > 0) {
        additionalIntakeFiles = await Promise.all(
          files.map((file) => {
            return uploadFile(file, facilityId);
          })
        );
      }
      const data = {
        files: intakeFiles.concat(additionalIntakeFiles),
      };
      await updateIntake(intakeId, data);
    } catch (e) {
      console.error(e);
    } finally {
      clearNewIntake();
      mixpanel.track("document upload", { email: email });
    }
  };

  removeFile = async (index) => {
    const { intakeId, intakeFiles, mixpanel, user: { email } = {} } = this.props;
    try {
      intakeFiles.splice(index, 1);
      await updateIntake(intakeId, { files: intakeFiles });
    } catch (e) {
      console.error(e);
    } finally {
      clearNewIntake();
      mixpanel.track("document removed", { email: email });
    }
  };

  render() {
    const {
      documents = [],
      facilityId,
      intakeId,
      intakeFiles,
      updateIntake,
      clearNewIntake,
      clearIntakeFile,
    } = this.props;
    const { files, isFileLoading } = this.state;
    return (
      <>
        <div className="d-flex align-center">
          <h3 className="flex-1-auto">Documents</h3>
          <FileInput
            files={files}
            onFilesAdded={this.setAndUploadFiles}
            onFileRemoved={this.removeFile}
            isFileLoading={isFileLoading}
            hideSelectedFiles
          />
        </div>
        <Table
          data={documents.map((doc) => ({
            ...doc,
            facilityId,
            intakeId,
            intakeFiles,
            updateIntake,
            clearNewIntake,
            clearIntakeFile,
          }))}
          config={config}
        />
      </>
    );
  }
}

function mapStateToProps(_, initialProps) {
  const { intakeId } = initialProps;
  const documentSelector = documentsForIntake(intakeId);
  return (state) => ({
    documents: documentSelector(state),
    facilityId: state.intake.all[intakeId] && state.intake.all[intakeId].facilityId,
    user: state.user,
  });
}

export default connect(mapStateToProps, { updateIntake, clearNewIntake, clearIntakeFile })(withMixpanel(Documents));
