import { FETCH_ASSESSMENTS_LIST, CREATE_RUG_ASSESSMENT } from "../actions/types";
import { normalize } from "./index";

const initialState = {
  all: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${FETCH_ASSESSMENTS_LIST}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${CREATE_RUG_ASSESSMENT}_FULFILLED`:
      const { ...assessment } = action.payload.data;
      return {
        ...state,
        all: {
          ...state.all,
          [assessment.id]: { ...assessment },
        },
      };
    case `${FETCH_ASSESSMENTS_LIST}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        all: {
          ...normalize(action.payload.data.map((item) => JSON.parse(item))),
        },
      };
    default:
      return state;
  }
}
