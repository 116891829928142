import { FETCH_PAYER, FETCH_PAYERS, FETCH_PLANS_FOR_PAYER } from "../actions/types";
import { normalize } from "./index";
import { groupBy } from "lodash";

const initialState = {
  all: {},
};

export function payersReducer(state = initialState, { type, payload = {} }) {
  switch (type) {
    case `${FETCH_PAYER}_FULFILLED`:
      return {
        ...state,
        all: {
          ...state.all,
          [payload.data.displayName]: {
            ...state.all[payload.data.displayName],
            [payload.data.id]: payload.data,
          },
        },
      };
    case `${FETCH_PAYERS}_FULFILLED`:
      const payersByDisplayName = groupBy(payload.data, (payer) => payer.displayName);
      const payersSortedByDisplayName = [];
      Object.keys(payersByDisplayName)
        .sort()
        .forEach((key) => (payersSortedByDisplayName[key] = normalize(payersByDisplayName[key])));
      return {
        ...state,
        all: {
          ...state.all,
          ...payersSortedByDisplayName,
        },
      };
    default:
      return state;
  }
}

export function insurancePlansReducer(state = initialState, { type, payload = {} }) {
  switch (type) {
    case `${FETCH_PLANS_FOR_PAYER}_FULFILLED`:
      return {
        ...state,
        all: {
          ...state.all,
          [payload.payerId]: {
            ...normalize(payload.data),
          },
        },
      };
    default:
      return state;
  }
}
