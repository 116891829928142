import React, { useState, useEffect } from "react";
import Modal from "../common/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import Filter from "../common/Filter";
import TextareaInput from "../common/TextareaInput";
import SnackBarMessage from "../common/SnackBarMessage";
import { useMixpanel } from "react-mixpanel-browser";
import { useAlertStatuses, useUpdateAlert } from "../api/caseManagementApi";
import { useQueryClient } from "@tanstack/react-query";

export const AlertDialog = (props) => {
  const mixpanel = useMixpanel();
  const [isModalOpen, toggleIsModalOpen] = useState(false);
  const [isMessageShown, setIsMessageShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { alert, email } = props;

  const alertStatusesQuery = useAlertStatuses();
  const updateAlertMutation = useUpdateAlert();
  const queryClient = useQueryClient();

  useEffect(() => {
    let hideNewMessageTimer = setTimeout(() => setIsMessageShown(false), 2900);
    return () => {
      clearTimeout(hideNewMessageTimer);
    };
  }, [isMessageShown]);

  if (alertStatusesQuery.isLoading) {
    return null;
  }

  const status = mapStatus(alert.status_id, alertStatusesQuery.data);
  const options = alertStatusesQuery.data.map((x) => ({ label: x.text, value: x.id }));

  return (
    <>
      <div className="align-center justify-around">
        <button className="btn btn-link d-flex align-center" onClick={() => toggleIsModalOpen(!isModalOpen)}>
          {alert.status_note && typeof alert.status_note === "string" && (
            <i className="fa fa-2x fa-sticky-note-o text-primary m-r-sm" aria-label="Status Note Exists" />
          )}
          <i className="fa fa-2x fa-pencil-square text-primary clickable m-r-sm" aria-label="Edit Document" />
          {status.label}
        </button>
      </div>
      <Modal open={isModalOpen} onClose={() => toggleIsModalOpen(!isModalOpen)} title="Manage An Alert">
        <Formik
          initialValues={{
            status: status.value,
            statusNote: alert.status_note || "",
          }}
          validationSchema={Yup.object().shape({
            status: Yup.number().required("Required"),
            statusNote: Yup.string(),
          })}
          onSubmit={(values, actions) => {
            updateAlertMutation.mutate(
              { id: alert.id, status_id: values.status, status_note: values.statusNote },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({ queryKey: ["alerts"] });
                  queryClient.invalidateQueries({ queryKey: ["alertsForPatient"] });
                },
                onError: (err) => {
                  setErrorMessage(err);
                  setIsMessageShown(true);
                },
                onSettled: () => {
                  toggleIsModalOpen(!isModalOpen);
                  mixpanel.track("alert status changes", { email: email });
                },
              }
            );
          }}
          render={({ handleSubmit, isSubmitting, setFieldValue, touched, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="control-label" htmlFor="status">
                  Status
                </label>
                <Filter
                  id="status"
                  className="w-xs m-b-n-xs"
                  selectHeight="30px"
                  isSearchable={false}
                  isLoading={alertStatusesQuery.isLoading}
                  defaultValue={status}
                  key={options.length}
                  options={options}
                  onChange={(option) => {
                    setFieldValue("status", option.value);
                  }}
                />
                {touched.status && errors.status && <div className="help-block text-danger">{errors.status}</div>}
              </div>
              <div className="form-group" style={{ height: 200 }}>
                <TextareaInput label="Status Notes" name="statusNote" style={{ height: 160, resize: "none" }} />
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                Save
              </button>
            </form>
          )}
        />
      </Modal>
      <SnackBarMessage isMessageShown={isMessageShown} snackBarClassName="bg-colour-warning" message={errorMessage} />
    </>
  );
};

const mapStatus = (alertStatusId, statuses) => {
  const matchedStatus = statuses.find((x) => x.id === alertStatusId);
  if (matchedStatus) {
    return { label: matchedStatus.text, value: matchedStatus.id };
  }
  return { label: "-", value: null };
};
