import React from "react";
import PropTypes from "prop-types";

export default function LabeledField({ className, style, label, labelBasis, children }) {
  return (
    <div className={className} style={{ display: "flex", ...style }}>
      <strong style={{ flex: `0 0 ${labelBasis}` }}>{label}:</strong>
      <div>{children}</div>
    </div>
  );
}

LabeledField.propTypes = {
  label: PropTypes.string,
  labelBasis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};
LabeledField.defaultProps = {
  labelBasis: "170px",
};
