import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UpdatesList from "./updates/UpdatesList";
import { updatesListSelector, updatesLocationSelector, updatesPayersSelector } from "./store/selectors";
import { fetchPayers, fetchUpdates, setUpdateDateFilterAndGetUpdates } from "./store/actions";
import CreateUpdateButton from "./updates/CreateUpdate";
import { DateFilter } from "./common/DateFilter";
import moment from "moment";
import { withMixpanel } from "react-mixpanel-browser";

const today = moment();

export class UpdatesPage extends Component {
  componentDidMount() {
    const { fetchPayers, fetchUpdates, startDate, endDate, mixpanel, user: { email } = {} } = this.props;
    typeof fetchUpdates === "function" && fetchUpdates(startDate, endDate);
    typeof fetchPayers === "function" && fetchPayers();
    mixpanel && mixpanel.track("visit update screen", { email: email });
  }

  render() {
    const { setUpdateDateFilterAndGetUpdates, startDate, endDate } = this.props;
    return (
      <>
        <div className="updates-panel m-b-sm">
          <h1 className="h2 text-primary flex-1-0-auto">Upcoming Updates</h1>
          <div className="m-r-sm">
            <CreateUpdateButton />
          </div>
          <button
            onClick={() =>
              (startDate !== today || endDate !== today) &&
              setUpdateDateFilterAndGetUpdates({ startDate: today, endDate: today })
            }
            className="btn btn-default m-r"
          >
            Today
          </button>
          <DateFilter endDate={endDate} startDate={startDate} setDateFilter={setUpdateDateFilterAndGetUpdates} />
        </div>
        <div className="hpanel">
          <div className="panel-body no-padding">
            <UpdatesList {...this.props} />
          </div>
        </div>
      </>
    );
  }
}

UpdatesPage.propTypes = {
  updates: PropTypes.array,
  payers: PropTypes.array,
  locations: PropTypes.array,
};

function mapStateToProps(state) {
  const {
    updates: {
      isLoading,
      filter: { startDate, endDate },
    },
  } = state;
  return {
    updates: updatesListSelector(state),
    payers: updatesPayersSelector(state),
    locations: updatesLocationSelector(state),
    startDate,
    endDate,
    isLoading,
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  fetchUpdates,
  fetchPayers,
  setUpdateDateFilterAndGetUpdates,
})(withMixpanel(UpdatesPage));
