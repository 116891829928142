import React, { Component } from "react";
import { Formik, Field, Form } from "formik";
import Panel from "./common/Panel";
import TextareaInput from "./common/TextareaInput";
import { API, Auth } from "aws-amplify";
import { apiName } from "./store/actions";

export default class ApiTestPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "N/A",
      response: "",
      headers: "",
    };
    this.setData = this.setData.bind(this);
    Auth.currentSession().then((data) => console.log(data.getIdToken().getJwtToken()));
  }

  setData(response) {
    this.setState({ status: response.status, response: response.data, headers: response.headers });
  }

  render() {
    return (
      <>
        <Panel heading={<h3>Request</h3>}>
          <Formik
            initialValues={{
              path: "stays",
              qparams: "",
              body: "",
              method: "get",
            }}
            onSubmit={(values, actions) => {
              const params = {
                response: true,
                queryStringParameters: JSON.parse(values.qparams),
                body: values.body,
              };
              const path = `/${values.path}`;
              console.log(values.method);
              API[values.method](apiName, path, params).then(
                (r) => this.setData(r),
                (e) => this.setData(e.response)
              );
            }}
            render={(formikProps) => (
              <Form>
                <div className="form-group">
                  <label>Path:</label>
                  <Field type="text" name="path" className="form-control" />
                </div>
                <div className="form-group">
                  <label>QueryParams:</label>
                  <Field type="text" name="qparams" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Method:</label>
                  <Field component="select" name="method" className="form-control">
                    <option value="get">Get</option>
                    <option value="post">Post</option>
                    <option value="put">Put</option>
                    <option value="delete">Delete</option>
                  </Field>
                </div>
                <TextareaInput label="Body" name="body" />
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              </Form>
            )}
          />
        </Panel>
        <Panel heading={<h3>Response</h3>}>
          <div style={{ margin: "10 0" }}>
            <label style={{ marginRight: "10px" }}>Response Code:</label>
            <span>{this.state.status}</span>
          </div>
          <label>Headers:</label>
          <pre>{JSON.stringify(this.state.headers, null, 2)}</pre>
          <label>Response</label>
          <pre>{JSON.stringify(this.state.response, null, 2)}</pre>
        </Panel>
      </>
    );
  }
}
