import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import SingleDatePicker from "../common/SingleDatePicker";
import Select from "react-select";
import { fetchRugCodes, createRugAssessment } from "../store/actions";

const AssessmentsListDialog = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [rugCodes, setRugCodes] = useState([]);

  const loadCodes = async (systemId) => {
    try {
      const codes = await fetchRugCodes(systemId).payload;
      const parsed_codes = codes.data.map((item) => JSON.parse(item));
      const code_options = parsed_codes.map((rugCode) => ({
        label: rugCode.code,
        value: rugCode.id,
      }));
      return code_options;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    loadCodes(props.stay.rugSystemId).then((data) => {
      if (isMounted) setRugCodes(data);
    });
    return () => (isMounted = false);
  }, [props.stay.rugSystemId]);

  return (
    <>
      <button className="btn btn-primary m-b-xxs" onClick={() => setModalOpen(!isModalOpen)}>
        {"Add Assessment"}
      </button>
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)} title="Add an Assessment">
        <Formik
          initialValues={{
            date: moment(),
            rug: undefined,
          }}
          validationSchema={Yup.object().shape({
            date: Yup.object().nullable().required("Date required"),
            endDate: Yup.object().nullable(),
            rug: Yup.string().required("RUG Code required"),
          })}
          onSubmit={(values, actions) => {
            props.createRugAssessment(props.stay.id, {
              assessmentDate: values.date.format("YYYY-MM-DD"),
              completedDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : null,
              rugCodeId: values.rug,
            });
            actions.setSubmitting(false);
            setModalOpen(false);
          }}
          render={({ values, handleSubmit, isSubmitting, setFieldValue, touched, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="date">Assessment Date</label>
                <div>
                  <SingleDatePicker
                    date={values.date}
                    onDateChange={(date) => setFieldValue("date", date)}
                    isOutsideRange={() => false}
                    id="date"
                  />
                </div>
                <label htmlFor="date">Completed Date (Optional)</label>
                <div>
                  <SingleDatePicker
                    date={values.endDate}
                    onDateChange={(date) => setFieldValue("endDate", date)}
                    isOutsideRange={(day) => day.isBefore(values.date, "day")}
                    id="endDate"
                    showClearDate
                  />
                </div>
                {touched.date && errors.date && <div className="help-block text-danger">{errors.date}</div>}
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="rug">
                  State RUG
                </label>
                <Select
                  id="rug"
                  options={rugCodes}
                  isLoading={rugCodes && rugCodes.length === 0}
                  onChange={(option) => setFieldValue("rug", option.value)}
                />
                {touched.rug && errors.rug && <div className="help-block text-danger">{errors.rug}</div>}
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                Save
              </button>
            </form>
          )}
        />
      </Modal>
    </>
  );
};

export default connect(null, { createRugAssessment })(AssessmentsListDialog);
