import moment from "moment";
import { createSelector } from "reselect";
import { normalize } from "../reducers";
import { sortBy, last, orderBy } from "lodash";
import { asListSelector } from "./common";

export const updatesSelector = (state) => state.updates.all;
export const alertsSelector = (state) => state.alerts.all;
export const ordersSelector = (state) => state.orders.all;
export const nurseNotesSelector = (state) => state.nurseNotes.all;
export const payersSelector = (state) => state.payers.all;
export const plansSelector = (state) => state.insurancePlans.all;
export const locationsSelector = (state) => state.stays.all;
export const facilitiesSelector = (state) => state.facilities.all;
export const facilitiesSelectorByRdsId = (state) => state.facilities.byRdsId;
export const diagnosesSelector = (state) => state.diagnoses.all;
export const rateHistorySelector = (state) => state.rateHistory.all;
export const serviceHistorySelector = (state) => state.serviceHistory.all;
export const assessmentsListSelector = (state) => state.assessmentsList.all;
export const stayDiagnosesSelector = (state) => state.stayDiagnoses.all;

export const payersByIndexSelector = createSelector(asListSelector(payersSelector), (payers = []) =>
  payers.reduce((acc, el) => {
    Object.keys(el).forEach((key) => (acc[key] = el[key]));
    return acc;
  }, {})
);

export const staysSelector = createSelector(
  asListSelector(locationsSelector),
  payersByIndexSelector,
  plansSelector,
  (stays, payers = {}, plans = {}) =>
    normalize(
      stays.map((stay) => {
        const payerPlans = plans[stay.payerId];
        return {
          ...stay,
          payer: payers[stay.payerId],
          billingInsurancePlan: payerPlans && payerPlans[stay.billingInsurancePlanId],
          deliveredInsurancePlan: payerPlans && payerPlans[stay.deliveredInsurancePlanId],
        };
      })
    )
);

export const filteredUpdatesSelector = createSelector(
  asListSelector(updatesSelector),
  (state) => state.updates.filter,
  (updates = [], { startDate, endDate } = {}) =>
    normalize(
      updates.filter((update) => {
        const dueDate = moment(update.dueDate);
        return (
          (!startDate || dueDate.isSameOrAfter(startDate, "day")) &&
          (!endDate || dueDate.isSameOrBefore(endDate, "day"))
        );
      })
    )
);

export const filteredAlertsSelector = createSelector(
  asListSelector(alertsSelector),
  (state) => state.alerts.filter.startDate,
  (state) => state.alerts.filter.endDate,
  (alerts, startDate, endDate) => {
    return alerts.filter((alert) => {
      const date = moment(alert.date);
      return (!startDate || date.isSameOrAfter(startDate, "day")) && (!endDate || date.isSameOrBefore(endDate, "day"));
    });
  }
);

export const alertLocationsSelector = createSelector(filteredAlertsSelector, (alerts) => {
  return Array.from(new Set(alerts.map((alert) => alert.facility.location)));
});

export const filteredAlertsByDatesSelector = (admitDate, dischargeDate) =>
  createSelector(asListSelector(alertsSelector), (alerts) => {
    return normalize(
      alerts.filter((alert) => {
        const date = moment(alert.date);
        return (
          (!admitDate || date.isSameOrAfter(moment(admitDate), "day")) &&
          (!dischargeDate || date.isSameOrBefore(moment(dischargeDate), "day"))
        );
      })
    );
  });

export const filteredStaysSelector = createSelector(
  staysSelector,
  (state) => state.stays.filter.startDate,
  (state) => state.stays.filter.endDate,
  (staysMap, startDate, endDate) => {
    return Object.values(staysMap).filter((stay) => {
      const date = moment(stay.admitDate);
      return date.isSameOrAfter(startDate, "day") && date.isSameOrBefore(endDate, "day");
    });
  }
);

export const alertsListSelector = createSelector(
  asListSelector(filteredAlertsSelector),
  asListSelector(staysSelector),
  (alerts = [], stays = []) => {
    return alerts.map((alert) => {
      const mostRecentStay = last(stays.filter((stay) => stay.patient && stay.patient.id === alert.patient.id));
      return {
        ...alert,
        stay: mostRecentStay || {},
      };
    });
  }
);

export const updatesListSelector = createSelector(
  asListSelector(filteredUpdatesSelector),
  staysSelector,
  (updates = [], stays = {}) => {
    return updates.map((update) => {
      return {
        ...update,
        stay: stays[update.stayId] || {},
        formattedDueDate: moment(update.dueDate).format("M/DD/YYYY"),
      };
    });
  }
);

export const sortedByDisplayNameAndNameTopPayersSelector = createSelector(
  payersSelector,
  (sortedByDisplayNamePayers) => {
    const payersDisplayNames = Object.keys(sortedByDisplayNamePayers);
    const sortedByDisplayNameAndNameTopPayers = {};
    payersDisplayNames.forEach((key) => {
      const sortedByDisplayNameAndNamePayers = sortBy(Object.values(sortedByDisplayNamePayers[key]), [
        (payer) => payer.name,
      ]);
      sortedByDisplayNameAndNameTopPayers[key] = sortedByDisplayNameAndNamePayers.filter(
        (sortedPayer) => sortedPayer.name === sortedByDisplayNameAndNamePayers[0].name
      );
    });
    return sortedByDisplayNameAndNameTopPayers;
  }
);

export const getAlertsForStaySelector = (admitDate, dischargeDate, patientId) =>
  createSelector(asListSelector(filteredAlertsByDatesSelector(admitDate, dischargeDate)), (alerts) => {
    return alerts.filter((alert) => {
      return alert.patient.id === patientId;
    });
  });

export const getOrdersForStaySelector = (stayId) =>
  createSelector(asListSelector(ordersSelector), (orders) => {
    return orders.filter((order) => order.stayId === stayId);
  });

export const getNurseNotesForStaySelector = (stayId) =>
  createSelector(asListSelector(nurseNotesSelector), (nurseNotes) => {
    return nurseNotes.filter((note) => note.stayId === stayId);
  });

export const getDiagnosesForStaySelector = (stayId) =>
  createSelector(asListSelector(stayDiagnosesSelector), (diagnoses) => {
    return diagnoses.filter((diagnosis) => diagnosis.stayId === stayId);
  });

export const getRateHistoryForStaySelector = (stayId) =>
  createSelector(
    (state) => {
      const stayRates = state.rateHistory.byStay[stayId];
      return stayRates && stayRates.map((rateId) => state.rateHistory.all[rateId]);
    },
    payersByIndexSelector,
    plansSelector,
    (rateHistory = [], payers = {}, plans = {}) =>
      rateHistory.map((rateRecord) => {
        const insurancePlans = plans[rateRecord.payerId];
        return {
          ...rateRecord,
          payer: payers[rateRecord.payerId],
          insurancePlan: insurancePlans && insurancePlans[rateRecord.planId],
        };
      })
  );

export const getPlansForPayerSelector = (payerId) =>
  createSelector(plansSelector, (plans = {}) => Object.values(plans[payerId] ?? {}).filter((plan) => plan.rank));

export const getServiceHistoryForPatientSelector = (patientId) =>
  createSelector(
    asListSelector((state) => state.serviceHistory.all[patientId]),
    (serviceHistory = []) => serviceHistory.map((serviceRecord) => serviceRecord)
  );

export const getAssessmentsListForStaySelector = asListSelector(assessmentsListSelector);

// All of these bad larries need unit tests.
export const updatesLocationSelector = createSelector(updatesListSelector, (updates) => {
  return Array.from(new Set(updates.map((update) => update.stay.location)));
});

export const updatesPayersSelector = createSelector(updatesListSelector, (updates) => {
  const filteredUpdates = updates.filter((update) => update.stay.payer !== undefined);
  return Array.from(new Set(filteredUpdates.map((update) => update.stay.payer.name)));
});

export const staysLocationSelector = createSelector(filteredStaysSelector, (stays) => {
  return Array.from(new Set(stays.map((stay) => stay.location)));
});

export const staysPayersSelector = createSelector(filteredStaysSelector, (stays) => {
  const filteredStays = stays.filter((stay) => stay.payer !== undefined);
  return Array.from(new Set(filteredStays.map((stay) => stay.payer.name)));
});

export const serviceSummariesSelectorForStay = (currentPayerId, stayId) =>
  createSelector(
    payersByIndexSelector,
    serviceHistorySelector,
    plansSelector,
    getOrdersForStaySelector(stayId),
    getDiagnosesForStaySelector(stayId),
    (payers = [], serviceHistory = {}, allPlans = {}, orders = [], diagnoses = []) => {
      const payerIds = serviceHistory[stayId] || [];
      const serviceSummaries = { old: [] };
      payerIds.forEach((payerId) => {
        const payer = payers[payerId];
        const payerPlans = Object.values(allPlans[payerId] || {}).filter((plan) => plan.rank);
        let plans = [];
        if (payerPlans) {
          const ordersByMcce = {};
          orders.forEach((order) =>
            order.mcceIds.forEach((mcceId) => (ordersByMcce[mcceId] = [...(ordersByMcce[mcceId] || []), order]))
          );
          const diagnosesByMcce = {};
          diagnoses.forEach((diag) =>
            diag.mcceDescriptions.forEach(
              (mcceDesc) => (diagnosesByMcce[mcceDesc] = [...(diagnosesByMcce[mcceDesc] || []), diag])
            )
          );
          plans = payerPlans.map((plan) => ({
            ...plan,
            medicallyComplexCareEvents: plan.medicallyComplexCareEvents.map((mcce) => ({
              ...mcce,
              orders: (ordersByMcce[mcce.id] || []).sort(({ date: dateA }, { date: dateB }) => {
                if (dateA < dateB) {
                  return 1;
                }
                if (dateA > dateB) {
                  return -1;
                }
                return 0;
              }),
              diagnoses: (diagnosesByMcce[mcce.description] || []).sort(({ date: dateA }, { date: dateB }) => {
                if (dateA < dateB) {
                  return 1;
                }
                if (dateA > dateB) {
                  return -1;
                }
                return 0;
              }),
            })),
          }));
        }
        if (payerId === currentPayerId) {
          serviceSummaries.current = { payer, plans, carveOuts: [] };
        } else {
          serviceSummaries.old.push({ payer, plans, carveOuts: [] });
        }
      });
      return serviceSummaries;
    }
  );

export const getAdlsForStay = (stayId) =>
  createSelector(
    asListSelector((state) => state.adls.all),
    (adls = []) => adls.filter((adl) => adl.stayId === stayId)
  );

export const BED_MOBILITY_CATEGORY = "RNS: Bed Mobility";
export const TRANSFER_CATEGORY = "RNS: Transfer";
export const WALKING_CATEGORY = "RNS: Walking";
export const DRESS_GROOM_CATEGORY = "RNS: Dressing / Grooming";
export const EAT_SWALLOW_CATEGORY = "RNS: Eating / Swallowing";
export const ADL_INDEX_CATEGORY = "ADL Index";
export const getMergedAdls = (stayId, admitDate) =>
  createSelector(getAdlsForStay(stayId), (adls = []) => {
    const mergedAdls = {};
    adls.forEach((adl) => {
      const timeDiff = moment(adl.assessmentDate).utc();
      const merged = mergedAdls[adl.assessmentDate] ?? {
        dateTime: adl.assessmentDate,
        bed: 0,
        transfer: 0,
        walk: 0,
        dressGroom: 0,
        eatSwallow: 0,
        adlIndex: 0,
        dayInStay: Math.ceil(moment.duration(timeDiff.diff(admitDate)).asDays()) + 1,
      };

      switch (adl.category) {
        case BED_MOBILITY_CATEGORY:
          merged.bed = adl.score;
          break;
        case TRANSFER_CATEGORY:
          merged.transfer = adl.score;
          break;
        case WALKING_CATEGORY:
          merged.walk = adl.score;
          break;
        case DRESS_GROOM_CATEGORY:
          merged.dressGroom = adl.score;
          break;
        case EAT_SWALLOW_CATEGORY:
          merged.eatSwallow = adl.score;
          break;
        default:
          if (adl.category.includes(ADL_INDEX_CATEGORY)) {
            merged.adlIndex = adl.score;
          }
          break;
      }
      mergedAdls[adl.assessmentDate] = merged;
    });
    return orderBy(Object.values(mergedAdls), "dateTime", "asc");
  });
