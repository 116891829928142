import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchInsurancePlans } from "../store/actions";
import { createRankedPlansForPayerSelector } from "../store/selectors/intake";
import Panel from "../common/Panel";
import Checkbox from "../common/Checkbox";
import { head, sortBy } from "lodash";

export class ClinicalServicesCheckboxes extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    checkedMcces: new Set(),
    checkedBillableItems: new Set(),
  });

  componentDidMount() {
    this.setInitialMcceChecked();
    this.getPlansForPayer();
  }

  componentDidUpdate(prevProps, prevState) {
    const { payerId, plans = [], billableItems = [], primaryPayerPlans } = this.props;
    if (
      ((!prevProps.plans || !prevProps.plans.length) && plans.length > 0) ||
      prevProps.primaryPayerPlans !== primaryPayerPlans
    ) {
      this.setInitialMcceChecked();
    }
    if (prevState !== this.state) {
      const { checkedMcces, checkedBillableItems } = this.state;
      this.props.onChange({
        plans: plans.map((plan) => {
          const { medicallyComplexCareEvents: mcces, careLevel, rank } = plan;
          return {
            careLevel,
            rank: Number(rank),
            mcces: mcces.map((mcce) => ({ description: mcce.description, checked: checkedMcces.has(mcce.id) })),
          };
        }),
        billableItems: billableItems.map((item) => ({
          description: item.description,
          checked: checkedBillableItems.has(item.id),
        })),
      });
    }
    if (prevProps.payerId !== payerId) {
      this.getPlansForPayer();
      this.setState(this.getInitialState());
    }
  }

  getPlansForPayer = () => {
    const { payerId, fetchInsurancePlans } = this.props;
    payerId && typeof fetchInsurancePlans === "function" && fetchInsurancePlans(payerId);
  };

  setInitialMcceChecked = () => {
    const { plans = [], primaryPayerPlans = [] } = this.props;
    const { checkedMcces, ...rest } = this.state;
    let newCheckedMcces = new Set(checkedMcces);
    plans.length > 0 &&
      primaryPayerPlans.length > 0 &&
      plans.forEach((plan) => {
        const primaryPayerPlan = head(
          primaryPayerPlans.filter((primaryPayerPlan) => primaryPayerPlan.careLevel === plan.careLevel)
        );
        (plan.medicallyComplexCareEvents || []).forEach(({ id, description }) => {
          const mcce =
            primaryPayerPlan && head(primaryPayerPlan.mcces.filter((mcce) => mcce.description === description));
          mcce && mcce.checked && newCheckedMcces.add(id);
        });
      });
    newCheckedMcces.size > 0 && this.setState({ ...rest, checkedMcces: newCheckedMcces });
  };

  setMcceChecked = (mcceId, checked) => {
    const { checkedMcces, ...rest } = this.state;
    let newCheckedMcces = new Set(checkedMcces);
    checked ? newCheckedMcces.add(mcceId) : newCheckedMcces.delete(mcceId);
    this.setState({ ...rest, checkedMcces: newCheckedMcces });
  };

  setBillableItemChecked = (itemId, checked) => {
    const { checkedBillableItems, ...rest } = this.state;
    let newCheckedItems = new Set(checkedBillableItems);
    checked ? newCheckedItems.add(itemId) : newCheckedItems.delete(itemId);
    this.setState({ ...rest, checkedBillableItems: newCheckedItems });
  };

  render() {
    const { payerId, plans, billableItems, isBillable } = this.props;
    const { checkedMcces, checkedBillableItems } = this.state;

    return (
      <>
        <h3>Clinical Services</h3>
        <Panel>
          {payerId && (
            <>
              <div className="col-sm-7 col-md-6">
                {!plans
                  ? "Loading payer plan information..."
                  : plans.length > 0
                  ? sortBy(plans, [(plan) => plan.rank]).map((plan, planIdx) => {
                      return (
                        <div key={planIdx}>
                          <h3 className="h4">
                            {plan.careLevel}
                            {plan.requirements ? ` (${plan.requirements})` : ""}:
                          </h3>
                          {(plan.medicallyComplexCareEvents || []).map(({ id, description }) => {
                            return (
                              <Checkbox
                                key={id}
                                label={description}
                                name={`mcce_${id}`}
                                checked={checkedMcces.has(id)}
                                onChange={(e) => this.setMcceChecked(id, e.target.checked)}
                              />
                            );
                          })}
                        </div>
                      );
                    })
                  : "No plans were found"}
              </div>
              {isBillable && (
                <div className="col-sm-5 col-md-6">
                  <h3 className="h4">Other billable items (carve outs & exclusions):</h3>
                  {!billableItems
                    ? "Loading payer billable items..."
                    : billableItems.map(({ id, description }) => (
                        <Checkbox
                          key={id}
                          label={description}
                          name={`billableItem_${id}`}
                          checked={checkedBillableItems.has(id)}
                          onChange={(e) => this.setBillableItemChecked(id, e.target.checked)}
                        />
                      ))}
                </div>
              )}
            </>
          )}
        </Panel>
      </>
    );
  }
}

const mapStateToProps = (state, initialProps) => {
  const { payerId } = initialProps;
  const plansSelector = createRankedPlansForPayerSelector(payerId);
  const plans = plansSelector(state);
  return {
    ...initialProps,
    plans,
    billableItems: [
      {
        id: "c280ca34-0068-4645-ab95-64eaf658fd3d",
        description: "Lenoxin",
      },
      {
        id: "46e5e78c-0a7a-40b5-965e-e284b1afae4a",
        description: "J0637 - caspofungin acetate, 5 mg",
      },
      {
        id: "dc457811-cc31-4c6d-a369-26f4fb087e18",
        description: "J0878 - daptomycin, 1 mg",
      },
      {
        id: "a196462e-02dc-425d-9610-d320ad3d9e66",
        description: "J0885 - epoetin alfa, (for non-esrd use), 1000 units",
      },
      {
        id: "8a61e7c1-d8c4-4522-91da-92652f342781",
        description: "J1335 - ertapenem sodium, 500 mg",
      },
      {
        id: "250116ee-9f27-4211-87eb-6283405fc2e4",
        description: "J2020 - linezolid, 200 mg",
      },
      {
        id: "58fdb59b-8584-4524-a456-4b17ac0b4ea3",
        description: "J3243 - tigecycline, 1 mg",
      },
      {
        id: "05f352ec-b5d8-45d2-bea3-5662d9e47087",
        description: "S0073 - aztreonam, 500 mg",
      },
      {
        id: "afa9b486-fea7-42ec-b3c0-50a7b6ff5918",
        description: "Specialty bed or mattress",
      },
      {
        id: "d12c69b0-2d8b-4a62-85e5-2fd71dd166c0",
        description: "Oxygen",
      },
      {
        id: "0bd0566c-15be-4c80-b5e6-9e2648076cef",
        description: "Ventilator",
      },
      {
        id: "9821c7c4-a16d-462c-85ad-c1269b1f9a25",
        description: "Wound vac",
      },
    ],
  };
};

export default connect(mapStateToProps, { fetchInsurancePlans })(ClinicalServicesCheckboxes);
