import { SET_SERVICE_HISTORY } from "../actions/types";

const initialState = {
  all: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_SERVICE_HISTORY:
      return {
        all: {
          ...state.all,
          [payload.stayId]: payload.payers,
        },
      };
    default:
      return state;
  }
}
