import React, { useMemo, useState } from "react";
import { debounce } from "lodash";

const DebouncedInput = ({ onChange, debounceTime = 500, ...rest }) => {
  const [value, setValue] = useState("");

  const debouncedOnChange = useMemo(
    () =>
      debounce((val) => {
        onChange(val);
      }, debounceTime),
    []
  );

  const handleChange = (e) => {
    const val = e.target.value;
    setValue(val);
    debouncedOnChange(val);
  };

  return <input {...rest} value={value} onChange={handleChange} />;
};

export default DebouncedInput;
