import React, { useState } from "react";
import { connect } from "react-redux";
import { updateUpdate } from "../store/actions";
import Filter from "../common/Filter";

function UpdateStatusDropdown({ update, updateUpdate }) {
  const [status, setStatus] = useState("Pending");
  const statusOptions = ["Pending", "Completed"].map((status) => ({
    label: status,
    value: status,
  }));
  return (
    <div style={{ marginTop: "-3px" }}>
      <Filter
        className="w-xs m-b-n-xs"
        selectHeight="30px"
        isSearchable={false}
        defaultValue={{ label: update.status || status, value: update.status || status }}
        key={statusOptions.length}
        options={statusOptions}
        onChange={(option) => {
          setStatus(option.value);
          updateUpdate({ id: update.id, status: option.value });
        }}
      />
    </div>
  );
}

export default connect(null, { updateUpdate })(UpdateStatusDropdown);
