import {
  FETCH_INTAKES,
  FETCH_INTAKE,
  SET_INTAKE_DATE_FILTER,
  CREATE_INTAKE,
  CLEAR_NEW_INTAKE,
  UPDATE_INTAKE,
  DELETE_INTAKE,
  CLEAR_INTAKE_FILE,
  SET_INTAKE_FILTERS,
} from "./types";
import { IntakeApi, Configuration } from "@medasync/intake-sdk";
import { Auth } from "aws-amplify";

const getIntakeApi = async () =>
  new IntakeApi(
    new Configuration({
      basePath: process.env.REACT_APP_INTAKE_API_BASE_URL,
      apiKey: (await Auth.currentSession()).getIdToken().getJwtToken(),
    })
  );

export function fetchIntakes(fromDate, toDate) {
  return {
    type: FETCH_INTAKES,
    payload: async () => (await getIntakeApi()).intakesGet(fromDate && fromDate.format(), toDate && toDate.format()),
  };
}

export function fetchIntake(id) {
  return {
    type: FETCH_INTAKE,
    payload: async () => (await getIntakeApi()).intakesIdGet(id),
  };
}

export function setIntakeDateFilter(startDate, endDate) {
  startDate && startDate.utc().startOf("date");
  endDate && endDate.utc().endOf("date");
  return {
    type: SET_INTAKE_DATE_FILTER,
    payload: {
      startDate,
      endDate,
    },
  };
}

export function setIntakeDateFilterAndFetchIntakes({ startDate, endDate }) {
  return (dispatch) => {
    dispatch(setIntakeDateFilter(startDate, endDate));
    dispatch(fetchIntakes(startDate, endDate));
  };
}
export function createIntake(data) {
  return {
    type: CREATE_INTAKE,
    payload: async () => (await getIntakeApi()).intakesPost(data),
  };
}

export function clearNewIntake() {
  return {
    type: CLEAR_NEW_INTAKE,
  };
}

export function clearIntakeFile(intakeId, fileS3Key) {
  return {
    type: CLEAR_INTAKE_FILE,
    payload: {
      intakeId: intakeId,
      filename: fileS3Key,
    },
  };
}

export function updateIntake(id, data) {
  return {
    type: UPDATE_INTAKE,
    payload: async () => (await getIntakeApi()).intakesIdPut(id, data),
  };
}

export function deleteIntake(id) {
  return {
    type: DELETE_INTAKE,
    payload: async () =>
      (await getIntakeApi()).intakesIdDelete(id).then((paiload) => {
        return { ...paiload, data: { deletedIntakeId: id } };
      }),
  };
}

export function setIntakeFilters(filterKey, selectedValues) {
  return {
    type: SET_INTAKE_FILTERS,
    payload: {
      filterKey,
      selectedValues,
    },
  };
}
