import React, { useState } from "react";
import Modal from "../common/Modal";

export default ({ itemId, title, description, deleteItemAction }) => {
  const [isModalOpen, toggleIsModalOpen] = useState(false);

  return (
    <>
      <i
        onClick={() => toggleIsModalOpen(!isModalOpen)}
        className="fa fa-2x fa-times-circle text-primary clickable"
        aria-label="Delete Intake"
      />
      <Modal open={isModalOpen} onClose={() => toggleIsModalOpen(!isModalOpen)} title={title} description={description}>
        <button
          onClick={() => {
            typeof deleteItemAction === "function" && deleteItemAction(itemId);
            toggleIsModalOpen(!isModalOpen);
          }}
          className="btn btn-primary"
        >
          Yes
        </button>
        <button onClick={() => toggleIsModalOpen(!isModalOpen)} className="btn btn-disabled m-l">
          No
        </button>
        <style jsx="true">
          {`
            .modal-header {
              text-align: left;
            }
            .modal-content {
              margin: 0 auto;
              width: 400px;
            }
          `}
        </style>
      </Modal>
    </>
  );
};
