export function isToggleTurnedOn(toggleName) {
  const fullToggleName = `REACT_APP_FT_${toggleName}`;
  if (process.env[fullToggleName] === undefined) {
    return true;
  }
  return Boolean(Number(process.env[fullToggleName]));
}

export function facilityToggle(toggleName, stayQuery) {
  const fullToggleName = `REACT_APP_FT_${toggleName}`;
  if (process.env[fullToggleName] === undefined) {
    return true;
  }
  return stayQuery && stayQuery.data && process.env[fullToggleName].split(" ").includes(String(stayQuery.data.facId));
}
