import React, { useState } from "react";
import TableMultiCell from "./TableMultiCell";

const hideStrings = (values) => {
  return "";
};

const addNumbers = (values) => {
  // Add all numbers, if there's a mix of types return ""
  let sum = 0;
  for (let value of values) {
    if (typeof value !== "number") return "";
    sum += value;
  }
  return sum;
};

export default ({ data, columns = [], rowClassName, numberTransform = addNumbers, stringTransform = hideStrings }) => {
  const [isExpandableOn, toggleExpandable] = useState(false);

  const compressValues = (values) => {
    // If values is not an array, display it.
    if (values.constructor !== Array) return values;
    if (typeof values[0] === "number") {
      return numberTransform(values);
    }
    if (typeof values[0] === "string") {
      return stringTransform(values);
    }
    return values;
  };

  return (
    <tr
      className={`clickable ${rowClassName}`}
      onClick={() => {
        toggleExpandable(!isExpandableOn);
      }}
    >
      {columns.map(({ key, transformer, bodyClassName }, i) => {
        let value = key ? data && data[key] : data;
        if (typeof transformer === "function") value = transformer(value);
        const totalValue = compressValues(value);
        return (
          <td key={`${key ? key : value}${i}`} className={bodyClassName}>
            {totalValue || totalValue === 0 ? totalValue : <br></br>}
            {isExpandableOn && i > 0 && (value || value === 0) && <TableMultiCell data={value} />}
          </td>
        );
      })}
    </tr>
  );
};
