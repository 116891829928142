import React, { useEffect, useState } from "react";
import moment from "moment";
import Table from "../common/Table";
import Filter from "../common/Filter";
import PatientLink from "./PatientLink";
import { withMixpanel } from "react-mixpanel-browser";
import { connect } from "react-redux";
import { useFacilityPayers, useStaysByFacility } from "../api/caseManagementApi";
import LoadingIndicator from "../common/LoadingIndicator";
import Checkbox from "../common/Checkbox";
import DebouncedInput from "../common/DebouncedInput";

export const getConfig = (payers, page, pageSize, totalCount, setPage, setPageSize) => ({
  columns: [
    {
      title: "Admit Date",
      key: "admitDate",
      transformer: (data) => moment(data).format("M/DD/YYYY"),
    },
    {
      title: "Patient",
      transformer: (data) => <PatientLink stayId={data.id} patient={data.patient} />,
    },
    {
      title: "Status",
      key: "dischargeDate",
      transformer: (data) => (data ? "Discharged" : "Current"),
    },
    {
      title: "Payer",
      transformer: ({ payerId }) => {
        if (payers) {
          const matchedPayer = payers.find((x) => x.id === payerId);
          return matchedPayer ? matchedPayer.displayName : "";
        }
        return "";
      },
    },
    {
      title: "Alerts",
      transformer: (data) => (data.alertCount === 0 ? "-" : data.alertCount),
    },
    {
      title: "Service Summary",
      transformer: (data) => (data.serviceCount === 0 ? "-" : data.serviceCount),
    },
    {
      title: "Location",
      key: "location",
    },
  ],
  search: {
    searchCounterName: "patient",
  },
  category: "Patients",
  pagination: {
    enabled: true,
    page: page,
    pageSize: pageSize,
    pageSizeOptions: [25, 50, 100],
    totalCount: totalCount,
    setPage: setPage,
    setPageSize: setPageSize,
  },
});

export const StaysList = (props) => {
  const {
    user: { email },
    mixpanel,
    startDate,
    endDate,
    facility,
  } = props;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  const [patientStayFilters, setPatientStayFilters] = useState({
    payers: [],
    status: [{ label: "Current", value: "Current" }],
    fromDate: startDate,
    toDate: endDate,
    recentAlerts: false,
    recentServices: false,
    patientName: undefined,
  });

  const staysQuery = useStaysByFacility(facility.rdsId, page, pageSize, patientStayFilters);
  const payersQuery = useFacilityPayers(facility.id);

  useEffect(() => {
    mixpanel && mixpanel.track("visit patients list", { email: email });
  }, []);

  useEffect(() => {
    setPatientStayFilters((x) => ({ ...x, fromDate: startDate, toDate: endDate }));
  }, [startDate, endDate]);

  const handleFilterChange = (filterKey, selectedValues) => {
    setPatientStayFilters((x) => ({ ...x, [filterKey]: selectedValues }));

    mixpanel.track("Patients filter added", {
      email: email,
      [filterKey]: selectedValues,
    });
  };

  if (payersQuery.isLoading) {
    return <LoadingIndicator />;
  }

  let filterOptions = {
    status: ["Discharged", "Current"].map((status) => {
      return {
        label: status,
        value: status,
      };
    }),
    payers: payersQuery.data.map((payer) => ({ label: payer.name, value: payer.id })),
  };

  if (staysQuery.isLoading) {
    return <LoadingIndicator />;
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const handlePatientNameChange = (name) => {
    setPage(0);
    setPatientStayFilters((x) => ({ ...x, patientName: name }));
  };

  const handleCheckboxChange = (e) => {
    const id = e.target.id;
    const val = e.target.checked;
    setPage(0);
    setPatientStayFilters((x) => ({ ...x, [id]: val }));
  };

  const config = getConfig(payersQuery.data, page, pageSize, staysQuery.data.count, setPage, handlePageSizeChange);

  return (
    <Table
      config={config}
      data={staysQuery.data.stays}
      isLoading={staysQuery.isLoading}
      headerContent={
        <div className="flex-column">
          {Object.keys(filterOptions).map((filterKey) => {
            const label =
              filterKey === "patientStatus" ? "Status" : filterKey.charAt(0).toUpperCase() + filterKey.slice(1);
            return (
              <Filter
                className="flex-1-auto p-xs"
                key={filterKey}
                label={label}
                value={patientStayFilters[filterKey]}
                options={filterOptions[filterKey]}
                onChange={(values) => handleFilterChange(filterKey, values)}
                isMulti
              />
            );
          })}
          <div className="d-flex p-xs align-baseline">
            <label className="control-label flex-0-auto w-xs m-r-xs">Patient Name</label>
            <DebouncedInput
              type="text"
              placeholder="Search by Patient"
              className={"form-control"}
              style={{ width: "300px" }}
              value={patientStayFilters.patientName}
              onChange={handlePatientNameChange}
            />
            <div className="d-flex m-l-sm align-baseline" style={{ gap: "8px" }}>
              <Checkbox
                id="recentAlerts"
                label="Recent Alerts"
                checked={patientStayFilters.recentAlerts}
                disabled={staysQuery.isLoading}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                id="recentServices"
                label="Recent Services"
                checked={patientStayFilters.recentServices}
                disabled={staysQuery.isLoading}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

StaysList.defaultProps = {
  stays: [],
  payers: [],
  locations: [],
};

function mapStateToProps(state) {
  return {
    alerts: state.alerts.all,
    user: state.user,
  };
}

export default connect(mapStateToProps)(withMixpanel(StaysList));
