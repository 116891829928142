import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Link, NavLink } from "react-router-dom";
import { useFullName, launchEmailClient } from "../utilities";
import IntakeInfo from "./IntakeInfo";
import Panel from "../common/Panel";
import {
  fetchIntake,
  clearNewIntake,
  fetchAllIntakeConditions,
  fetchAllIntakeSymptoms,
  fetchAllIntakeMeds,
} from "../store/actions";
import Medications from "./Medications";
import Conditions from "./Conditions";
import ServiceSummary from "./ServiceSummary";
import Documents from "./Documents";
import Insights from "./insights";
import ServiceNotes from "./ServiceNotes";
import { isToggleTurnedOn } from "../common/FeatureToggle";
import {
  createConditionsUniqByIcd10CodeExcludeReturnToProviderSelector,
  intakePrimaryPayerSelector,
} from "../store/selectors";
import EditIntake from "./EditIntake";
import { withMixpanel } from "react-mixpanel-browser";

export class IntakePage extends Component {
  componentDidMount() {
    const { id } = this.props;
    id && this.getIntakeInfo(id);
  }

  getIntakeInfo = (intakeId) => {
    const {
      fetchIntake,
      clearNewIntake,
      fetchAllIntakeConditions,
      fetchAllIntakeSymptoms,
      fetchAllIntakeMeds,
    } = this.props;
    typeof fetchIntake === "function" &&
      fetchIntake(intakeId)
        .then((data) => {
          const { value: { data: intake } = {} } = data;
          const intakeFileNames = intake && intake.files && intake.files.map((file) => file.s3Key);
          if (intakeFileNames) {
            typeof fetchAllIntakeConditions === "function" &&
              fetchAllIntakeConditions(intake.id, intakeFileNames).catch((err) => console.error(err));
            typeof fetchAllIntakeSymptoms === "function" &&
              fetchAllIntakeSymptoms(intake.id, intakeFileNames).catch((err) => console.error(err));
            typeof fetchAllIntakeMeds === "function" &&
              fetchAllIntakeMeds(intake.id, intakeFileNames).catch((err) => console.error(err));
          }
        })
        .catch((err) => console.error(err));
    typeof clearNewIntake === "function" && clearNewIntake();
  };

  get tabs() {
    const { match: { url } = {} } = this.props;
    return [
      {
        title: "Insights",
        path: url,
        exact: true,
        component: Insights,
      },
      {
        title: "Service Summary",
        path: `${url}/services`,
        component: ServiceSummary,
      },
      {
        title: "Medications",
        path: `${url}/medications`,
        component: Medications,
      },
      {
        title: "Conditions",
        path: `${url}/conditions`,
        component: Conditions,
        toggleName: "CONDITIONS",
      },
      {
        title: "Documents",
        path: `${url}/documents`,
        component: Documents,
        toggleName: "DOCUMENTS",
      },
      {
        title: "Service Notes",
        path: `${url}/notes`,
        component: ServiceNotes,
        toggleName: "SERVICE_NOTES",
      },
    ].filter((tab) => isToggleTurnedOn(tab.toggleName));
  }

  render() {
    const navigationTabs = this.tabs;
    const {
      intake,
      id,
      facilities,
      intakePrimaryPayer = {},
      match: { path } = {},
      mixpanel,
      user: { email } = {},
    } = this.props;
    const facilityName = intake && facilities[intake.facilityId] && facilities[intake.facilityId].name;
    const intakeWithPrimaryPayerFullInfo = intake && {
      ...intake,
      primaryPayer: { ...intake.primaryPayer, ...intakePrimaryPayer },
    };

    return intake ? (
      <>
        <Panel style={{ margin: "-16px -16px 0 -16px" }}>
          <div className="d-flex align-center">
            <h1 className="flex-1-auto">{useFullName(intake)}</h1>
            <span>
              {isToggleTurnedOn("EDIT_INTAKE") && (
                <Link to={`/intakes/edit/${intake.id}`} className="btn btn-default m-r-sm">
                  Edit
                </Link>
              )}
              <button
                className="btn btn-default"
                onClick={() => {
                  launchEmailClient("intake record");
                  mixpanel.track("user shares", { email: email });
                }}
              >
                Share
              </button>
            </span>
          </div>
        </Panel>
        <IntakeInfo facilityName={facilityName} {...intakeWithPrimaryPayerFullInfo} />
        <Switch>
          {navigationTabs.map((tab) => (
            <Route
              exact
              key={tab.path}
              {...tab}
              component={(props) => {
                const { match } = props;
                return (
                  <>
                    <ul className="nav nav-tabs">
                      {navigationTabs.map((navTab) => {
                        return (
                          <li
                            role="presentation"
                            key={navTab.path}
                            className={match.path === navTab.path ? "active" : ""}
                          >
                            <NavLink to={navTab.path} exact>
                              {navTab.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="hpanel tab-pane">
                      <div className="panel-body">
                        <tab.component
                          {...props}
                          intakeId={id}
                          intakeConditionsSelector={createConditionsUniqByIcd10CodeExcludeReturnToProviderSelector}
                          intakeFiles={intake.files}
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            />
          ))}
          <Route exact path={`${path}/edit/:id`} component={EditIntake} />
        </Switch>
      </>
    ) : null;
  }
}

function mapStateToProps(_, ownProps) {
  const {
    match: {
      params: { id },
    },
  } = ownProps;
  return (state) => ({
    id,
    intake: state.intake.all[id],
    facilities: state.facilities.all,
    intakePrimaryPayer: id && intakePrimaryPayerSelector(id)(state),
    user: state.user,
  });
}

export default connect(mapStateToProps, {
  fetchIntake,
  clearNewIntake,
  fetchAllIntakeConditions,
  fetchAllIntakeSymptoms,
  fetchAllIntakeMeds,
})(withMixpanel(IntakePage));
