import React from "react";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import { Formik } from "formik";
import moment from "moment";
import Select from "react-select";
import { createUpdate, fetchFacilities, fetchPatientsByFacility } from "../store/actions";
import { useFullName } from "../utilities";
import SingleDatePicker from "../common/SingleDatePicker";
import * as Yup from "yup";

class CreateUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      facilityOptions: [],
      patientOptions: [],
    };
  }
  openModal(open) {
    this.setState({ open });
  }
  async loadFacilities() {
    try {
      const facilities = await fetchFacilities().payload;
      const facility_options = facilities.data.map((facility) => ({
        label: facility.name,
        value: facility.id,
      }));
      this.setState({ facilityOptions: facility_options });
    } catch (error) {
      console.log(error);
    }
  }
  async fetchPatientsByFacility(facId) {
    try {
      this.setState({ patientOptions: [] });
      const patients = await fetchPatientsByFacility(facId).payload;
      const patient_options = patients.data.map((patient) => ({
        label: useFullName(patient),
        value: patient.id,
      }));
      this.setState({ patientOptions: patient_options });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.loadFacilities();
  }

  render() {
    return (
      <>
        <button className="btn btn-primary m-b-xxs" onClick={() => this.openModal(true)}>
          Create Update
        </button>
        <Modal
          open={this.state.open}
          onClose={() => {
            this.openModal(false);
          }}
          title="Create An Update"
        >
          <Formik
            initialValues={{
              facility: undefined,
              patient: undefined,
              date: moment(),
            }}
            validationSchema={Yup.object().shape({
              facility: Yup.string().required("Facility required"),
              patient: Yup.string().required("Patient required"),
              date: Yup.object().nullable().required("Date required"),
            })}
            onSubmit={(values, actions) => {
              this.props.createUpdate({
                dueDate: values.date.format("YYYY-MM-DD"),
                patientId: values.patient,
              });
              actions.setSubmitting(false);
              this.openModal(false);
            }}
            render={({ values, handleSubmit, isSubmitting, setFieldValue, setFieldTouched, touched, errors }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="control-label" htmlFor="facility">
                    Facility
                  </label>
                  <Select
                    id="facility"
                    options={this.state.facilityOptions}
                    isLoading={this.state.facilityOptions.length === 0}
                    onChange={(option) => {
                      this.fetchPatientsByFacility(option.value);
                      setFieldValue("facility", option.value);
                    }}
                    onBlur={() => setFieldTouched("facility", true)}
                  />
                  {touched.facility && errors.facility && (
                    <div className="help-block text-danger">{errors.facility}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="patient">
                    Patient Stay
                  </label>
                  <Select
                    id="patient"
                    options={this.state.patientOptions}
                    isDisabled={values.facility === undefined}
                    isLoading={this.state.patientOptions.length === 0}
                    onChange={(option) => setFieldValue("patient", option.value)}
                    onBlur={() => setFieldTouched("patient", true)}
                  />
                  {touched.patient && errors.patient && <div className="help-block text-danger">{errors.patient}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="date">Due Date</label>
                  <div>
                    <SingleDatePicker
                      date={values.date}
                      onDateChange={(date) => setFieldValue("date", date)}
                      id="date"
                    />
                  </div>
                  {touched.date && errors.date && <div className="help-block text-danger">{errors.date}</div>}
                </div>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  Save
                </button>
              </form>
            )}
          />
        </Modal>
      </>
    );
  }
}

export default connect(null, { createUpdate })(CreateUpdate);
