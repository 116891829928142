import React from "react";
import { connect } from "react-redux";
import { facilitiesSelectorByRdsId } from "../store/selectors/stays";
import { Link } from "react-router-dom";
import { setFacility } from "../store/actions";

export class FacilityLink extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {};
  }

  handleClick() {
    this.props.setFacility(this.props.facility);
    this.props.onFacilityChange(this.props.facility);
  }

  render() {
    const { facility } = this.props;
    return facility ? (
      <Link to={`/patients/facility/${facility.rdsId}`} onClick={() => this.handleClick()}>
        {facility.name}
      </Link>
    ) : null;
  }
}

function mapStateToProps(_, initialProps) {
  const { facilityRdsId } = initialProps;
  return (state) => {
    return {
      facility: facilitiesSelectorByRdsId(state)[facilityRdsId],
    };
  };
}
export default connect(mapStateToProps, { setFacility })(FacilityLink);
