import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AlertsList from "./AlertsList";
import AlertsSummary from "./AlertsSummary";
import { connect } from "react-redux";
import { filteredAlertsSelector, alertLocationsSelector, getTypeValueObject } from "./store/selectors";
import {
  fetchAlertCategories,
  fetchAlertStatuses,
  fetchAlertTypes,
  setAlertDateFilterAndGetAlerts,
  fetchStays,
} from "./store/actions";
import { DateFilter } from "./common/DateFilter";
import { withMixpanel } from "react-mixpanel-browser";
import LoadingIndicator from "./common/LoadingIndicator";
import { useAlertStatuses, useAlerts } from "./api/caseManagementApi";

export const AlertsPage = (props) => {
  const { startDate, endDate, mixpanel, user: { email } = {}, setAlertDateFilterAndGetAlerts } = props;
  const [filters, setFilters] = useState({ filters: { alertType: undefined } });

  useEffect(() => {
    mixpanel && mixpanel.track("visit alert screen", { email: email });

    props.categories || props.fetchAlertCategories();
    props.statuses || props.fetchAlertStatuses();
    props.types || props.fetchAlertTypes();
  }, []);

  useAlertStatuses();

  const alertsQuery = useAlerts(startDate, endDate);

  const handleFilterChange = (selectedValue) => {
    setFilters({
      filters: {
        alertType: selectedValue,
      },
    });
  };

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }} className="m-b-sm">
        <h1 className="h2 text-primary" style={{ flex: "1 0 auto" }}>
          Alerts
        </h1>
        <DateFilter startDate={startDate} endDate={endDate} setDateFilter={setAlertDateFilterAndGetAlerts} />
      </div>
      {alertsQuery.isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="m-b-sm">
            <AlertsSummary
              alerts={alertsQuery.data}
              handleFilterChange={handleFilterChange}
              typeValueObj={props.typeValues}
            />
          </div>
          <div className="hpanel">
            <div className="panel-body no-padding">
              <AlertsList {...props} alerts={getVisibleAlerts(alertsQuery.data, filters)} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

const getVisibleAlerts = (alerts, filters) =>
  alerts.filter((alert) => !filters.alertType || filters.alertType === alert.type_id);

AlertsPage.propTypes = {
  alerts: PropTypes.array,
  locations: PropTypes.array,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    alerts: {
      filter: { startDate, endDate },
      categories,
      statuses,
      types,
    },
  } = state;
  return {
    alerts: filteredAlertsSelector(state),
    startDate,
    endDate,
    categories,
    statuses,
    types,
    locations: alertLocationsSelector(state),
    typeValues: getTypeValueObject(state),
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  fetchAlertCategories,
  fetchAlertStatuses,
  fetchAlertTypes,
  setAlertDateFilterAndGetAlerts,
  fetchStays,
  getTypeValueObject,
})(withMixpanel(AlertsPage));
