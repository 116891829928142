import React from "react";

export default ({ label, ...rest }) => {
  return (
    <div className="checkbox">
      <label>
        <input type="checkbox" {...rest} /> {label}
      </label>
    </div>
  );
};
