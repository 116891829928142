import React, { useState } from "react";

export default ({ data, columns = [], rowClassName }) => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  const handleMouseEnter = (id) => {
    setActiveTooltip(id);
  };

  const handleMouseLeave = () => {
    setActiveTooltip(null);
  };

  return (
    <tr className={rowClassName}>
      {columns.map(({ key, transformer, bodyClassName, hoverContent }, i) => {
        let value = key ? data[key] : data;

        return (
          <td
            key={`${key ? key : value}${i}`}
            className={bodyClassName}
            onMouseEnter={() => (hoverContent ? handleMouseEnter(key ? key : value) : {})}
            onMouseLeave={() => (hoverContent ? handleMouseLeave() : {})}
          >
            {typeof transformer === "function" ? transformer(value) : value}
            {typeof hoverContent === "function" && activeTooltip === key && value && (
              <div
                style={{
                  background: "#f1f3f6",
                  border: "1px solid",
                  opacity: 0.9,
                  padding: "10px",
                  position: "absolute",
                }}
              >
                {hoverContent(value)}
              </div>
            )}
          </td>
        );
      })}
    </tr>
  );
};
