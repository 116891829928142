import { FETCH_ALL_INTAKE_MEDS, FETCH_ALL_INTAKE_CONDITIONS, FETCH_ALL_INTAKE_SYMPTOMS } from "./types";
import { defaultInit } from "./index";
import { API } from "aws-amplify";

export const apiName = "OrcaApi";

export function fetchAllIntakeMeds(intakeId, filenames = []) {
  return {
    type: FETCH_ALL_INTAKE_MEDS,
    payload: async () =>
      await Promise.all(
        filenames.map(async (filename) => {
          return {
            [filename]: await API.get(apiName, `/medications/${filename}`, defaultInit).then(({ data }) => data),
          };
        })
      ).then((payload) => ({
        intakeId,
        data: payload,
      })),
  };
}

export function fetchAllIntakeConditions(intakeId, filenames = []) {
  return {
    type: FETCH_ALL_INTAKE_CONDITIONS,
    payload: async () =>
      await Promise.all(
        filenames.map(async (filename) => {
          return {
            [filename]: await API.get(apiName, `/conditions/${filename}`, defaultInit).then(({ data }) => data),
          };
        })
      ).then((payload) => ({
        intakeId,
        data: payload,
      })),
  };
}

export function fetchAllIntakeSymptoms(intakeId, filenames = []) {
  return {
    type: FETCH_ALL_INTAKE_SYMPTOMS,
    payload: async () =>
      await Promise.all(
        filenames.map(async (filename) => {
          return {
            [filename]: await API.get(apiName, `/symptoms/${filename}`, defaultInit).then(({ data }) => data),
          };
        })
      ).then((payload) => ({
        intakeId,
        data: payload,
      })),
  };
}
