import React from "react";
import moment from "moment";
import Table from "../common/Table";
import Filter from "../common/Filter";
import LoadingIndicator from "../common/LoadingIndicator";
import PatientLink from "../patient/PatientLink";
import DeleteUpdateButton from "./DeleteUpdateButton";
import UpdateStatusDropdown from "./UpdateStatusDropdown";
import { setUpdateFilters } from "../store/actions";
import { withMixpanel } from "react-mixpanel-browser";
import { connect } from "react-redux";

export const config = {
  defaultSort: {
    column: 0,
    direction: "descending",
  },
  search: {
    keys: [
      {
        name: "stay.patient.lastName",
        weight: 0.5,
      },
      {
        name: "stay.patient.firstName",
        weight: 0.5,
      },
    ],
    placeholder: "Search by Patient",
  },
  columns: [
    {
      title: "Date",
      key: "formattedDueDate",
      sortValue: (data) => moment(data.dueDate).unix(),
      sortable: true,
    },
    {
      title: "Patient",
      transformer: (data) => <PatientLink stayId={data.stayId} patient={data.stay.patient} />,
      sortKey: "stay.patient.lastName",
      sortable: true,
    },
    {
      title: "Payer",
      transformer: ({ stay }) => stay.payer && stay.payer.displayName,
    },
    {
      title: "Length of Stay",
      transformer: ({ stay }) => `${stay.lengthOfStay} days`,
      sortKey: "lengthOfStay",
      sortable: true,
    },
    {
      title: "Status",
      transformer: (update) => {
        return <UpdateStatusDropdown update={update} />;
      },
    },
    {
      transformer: (update) => {
        return <DeleteUpdateButton update={update} />;
      },
      bodyClassName: "text-right",
    },
  ],
  category: "Updates",
};

export class UpdatesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFilterChange(filterKey, selectedValues) {
    const { user, mixpanel, updateFilters } = this.props;
    const email = user.email;

    if (selectedValues.length > updateFilters[filterKey].length) {
      const newFilter = selectedValues
        .filter((value) => !updateFilters[filterKey].includes(value))
        .map((filters) => filters.value);

      mixpanel.track("Updates filter added", {
        email: email,
        [filterKey]: newFilter,
      });
    }
    this.props.setUpdateFilters(filterKey, selectedValues);
  }

  filterUpdates() {
    const { updateFilters, updates } = this.props;
    const { payers = [], locations = [] } = Object.entries(updateFilters).reduce(
      (selectedVals, [key, selected]) => ({
        ...selectedVals,
        [key]: selected.map(({ value }) => value),
      }),
      {}
    );

    return updates.filter((update) => {
      return (
        (payers.length === 0 || payers.includes(update.stay.payer && update.stay.payer.displayName)) &&
        (locations.length === 0 || locations.includes(update.stay.location))
      );
    });
  }

  render() {
    const { updateFilters, updates, payers, locations, isLoading } = this.props;

    const payersOptions = payers.map((payer) => ({ label: payer, value: payer }));
    const locationOptions = locations.map((location) => ({ label: location, value: location }));

    let filterOptions = {
      payers: payersOptions,
      locations: locationOptions,
    };

    const updateList = this.filterUpdates();

    if (isLoading && !updates.length) {
      return <LoadingIndicator />;
    }
    return (
      <>
        <Table
          config={config}
          data={updateList}
          headerContent={
            <div className="flex-column">
              {Object.keys(filterOptions).map((filterKey) => {
                const label = filterKey.charAt(0).toUpperCase() + filterKey.slice(1);
                return (
                  <Filter
                    className="flex-1-auto p-xs"
                    key={filterKey}
                    label={label}
                    value={updateFilters[filterKey]}
                    options={filterOptions[filterKey]}
                    onChange={(values) => this.handleFilterChange(filterKey, values)}
                    isMulti
                  />
                );
              })}
            </div>
          }
        />
      </>
    );
  }
}

UpdatesList.defaultProps = {
  updateFilters: {},
  updates: [],
  payers: [],
  locations: [],
};

function mapStateToProps(state) {
  const {
    filters: { updateFilters },
  } = state;
  return {
    updateFilters,
    user: state.user,
  };
}

export default connect(mapStateToProps, { setUpdateFilters })(withMixpanel(UpdatesList));
