import {
  CREATE_UPDATE,
  FETCH_ALERT_CATEGORIES,
  FETCH_ALERT_STATUSES,
  FETCH_ALERT_TYPES,
  FETCH_FACILITIES,
  FETCH_PATIENTS_BY_FACILITY,
  FETCH_STAYS,
  FETCH_PLANS_FOR_PAYER,
  FETCH_UPDATES,
  SET_UPDATE_DATE_FILTER,
  SET_ALERTS_DATE_FILTER,
  SET_STAY_DATE_FILTER,
  SET_CUSTOM_FILTERS,
  FETCH_STAY_BY_PATIENT_AND_DATE,
  FETCH_PAYERS,
  FETCH_DIAGNOSES,
  FETCH_REPORTS,
  FETCH_REPORT_CREDS,
  DELETE_UPDATE,
  UPDATE_UPDATE,
  GET_CURRENT_AUTHENTICATED_USER,
  SET_SERVICE_HISTORY,
  UPDATE_RATE_HISTORY,
  SET_ALERTS_FILTERS,
  SET_UPDATE_FILTERS,
  FETCH_RUG_CODES,
  CREATE_RUG_ASSESSMENT,
  SET_FACILITY,
} from "./types";

import { API, Auth } from "aws-amplify";
import axios from "axios";

export const apiName = "CaseManagementApi";

export const defaultInit = { response: true }; //return axios response object for less refactoring
const DATE_FMT = "YYYY-MM-DD";

export * from "./intake";
export * from "./orca";

export function fetchFacilities() {
  return {
    type: FETCH_FACILITIES,
    payload: API.get(apiName, `/facilities`, defaultInit).catch((err) => console.error(err)),
  };
}

export function fetchPatientsByFacility(id) {
  return {
    type: FETCH_PATIENTS_BY_FACILITY,
    payload: API.get(apiName, `/facilities/${id}/patients`, defaultInit),
  };
}

export function fetchStays(fromDate, toDate) {
  return {
    type: FETCH_STAYS,
    payload: API.get(apiName, "/stays", {
      ...defaultInit,
      queryStringParameters: { fromDate: fromDate.format(DATE_FMT), toDate: toDate.format(DATE_FMT) },
    }),
  };
}

export function fetchStayByPatientIdAndDate(patientId, date) {
  return {
    type: FETCH_STAY_BY_PATIENT_AND_DATE,
    payload: API.get(apiName, `/patients/${patientId}/stays`, {
      ...defaultInit,
      queryStringParameters: { beforeDate: date },
    }).then(({ data }) => ({ data, date, patientId })),
  };
}

export function createUpdate(update) {
  return {
    type: CREATE_UPDATE,
    payload: API.post(apiName, `/updates`, { ...defaultInit, body: update }),
  };
}

export function deleteUpdate(id) {
  return {
    type: DELETE_UPDATE,
    payload: async () =>
      await API.del(apiName, `/updates/${id}`).then((paiload) => {
        return { ...paiload, data: { deletedUpdateId: id } };
      }),
  };
}

export function updateUpdate(updatedUpdate) {
  return {
    type: UPDATE_UPDATE,
    payload: API.put(apiName, `/updates/${updatedUpdate.id}`, {
      ...defaultInit,
      body: { status: updatedUpdate.status },
    }),
  };
}

export function fetchPayers() {
  return {
    type: FETCH_PAYERS,
    payload: API.get(apiName, "/payers", defaultInit).catch((err) => console.error(err)),
  };
}

export function fetchInsurancePlans(payerId) {
  return {
    type: FETCH_PLANS_FOR_PAYER,
    payload: API.get(apiName, `/payers/${payerId}/insurancePlans`, defaultInit).then(({ data }) => {
      return {
        data,
        payerId,
      };
    }),
  };
}

export function fetchAlertCategories() {
  return {
    type: FETCH_ALERT_CATEGORIES,
    payload: API.get(apiName, "/alerts/categories", defaultInit),
  };
}

export function fetchAlertStatuses() {
  return {
    type: FETCH_ALERT_STATUSES,
    payload: API.get(apiName, "/alerts/statuses", defaultInit),
  };
}

export function fetchAlertTypes() {
  return {
    type: FETCH_ALERT_TYPES,
    payload: API.get(apiName, "/alerts/types", defaultInit),
  };
}

export function fetchUpdates(startDate, endDate) {
  return {
    type: FETCH_UPDATES,
    payload: API.get(apiName, "/updates", {
      ...defaultInit,
      queryStringParameters: { startDate: startDate.format(DATE_FMT), endDate: endDate.format(DATE_FMT) },
    }),
  };
}

export function fetchAnalyticsReportCreds(reportId) {
  return {
    type: FETCH_REPORT_CREDS,
    payload: API.get(apiName, `/reports/${reportId}`, defaultInit),
  };
}

export function fetchAnalyticsReports() {
  return {
    type: FETCH_REPORTS,
    payload: API.get(apiName, `/reports`, defaultInit),
  };
}

export function setUpdateDateFilter(startDate, endDate) {
  return {
    type: SET_UPDATE_DATE_FILTER,
    payload: {
      startDate,
      endDate,
    },
  };
}

export function setUpdateDateFilterAndGetUpdates({ startDate, endDate }) {
  return (dispatch) => {
    if (startDate && endDate) {
      dispatch(setUpdateDateFilter(startDate, endDate));
      dispatch(fetchUpdates(startDate, endDate));
    }
  };
}

export function setAlertDateFilter(startDate, endDate) {
  return {
    type: SET_ALERTS_DATE_FILTER,
    payload: {
      startDate,
      endDate,
    },
  };
}

export function setAlertDateFilterAndGetAlerts({ startDate, endDate }) {
  return (dispatch) => {
    if (startDate) {
      dispatch(setAlertDateFilter(startDate, endDate));
    }
  };
}

export function setStayDateFilter(startDate, endDate) {
  return {
    type: SET_STAY_DATE_FILTER,
    payload: {
      startDate,
      endDate,
    },
  };
}

export function setStayDateFilterAndGetStays({ startDate, endDate }) {
  return (dispatch) => {
    if (startDate && endDate) {
      dispatch(setStayDateFilter(startDate, endDate));
      dispatch(fetchStays(startDate, endDate));
    }
  };
}

export function setStayDateFilterAndGetStaysByFacility({ startDate, endDate }) {
  return (dispatch) => dispatch(setStayDateFilter(startDate, endDate));
}

export function setCustomFilters(filters) {
  return {
    type: SET_CUSTOM_FILTERS,
    payload: filters,
  };
}

export function fetchDiagnoses(query, maxList) {
  return {
    type: FETCH_DIAGNOSES,
    payload: axios
      .get(process.env.REACT_APP_API_ICDCM_URL, {
        params: {
          sf: "code,name",
          maxList: maxList ? "" : 20,
          terms: query,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      }),
  };
}

export function getCurrentAuthenticatedUser() {
  return {
    type: GET_CURRENT_AUTHENTICATED_USER,
    payload: Auth.currentAuthenticatedUser(),
  };
}

export function setServiceHistory(stayId, payers) {
  return {
    type: SET_SERVICE_HISTORY,
    payload: {
      stayId,
      payers,
    },
  };
}

export function updateRateHistory(rateId, planId) {
  return {
    type: UPDATE_RATE_HISTORY,
    payload: API.put(apiName, `/patientrates/${rateId}`, {
      ...defaultInit,
      body: { planId },
    }),
  };
}

export function setAlertFilters(filterKey, selectedValues) {
  return {
    type: SET_ALERTS_FILTERS,
    payload: {
      filterKey,
      selectedValues,
    },
  };
}

export function setUpdateFilters(filterKey, selectedValues) {
  return {
    type: SET_UPDATE_FILTERS,
    payload: {
      filterKey,
      selectedValues,
    },
  };
}

export function fetchRugCodes(systemId) {
  return {
    type: FETCH_RUG_CODES,
    payload: API.get(apiName, "/rugcodes", {
      ...defaultInit,
      queryStringParameters: { systemId: systemId },
    }),
  };
}

export function createRugAssessment(stayId, rugAssessment) {
  return {
    type: CREATE_RUG_ASSESSMENT,
    payload: API.post(apiName, `/stays/${stayId}/rugAssessments`, { ...defaultInit, body: rugAssessment }),
  };
}

export function setFacility(facility) {
  return {
    type: SET_FACILITY,
    payload: facility,
  };
}
