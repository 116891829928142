import {
  FETCH_ALERTS,
  FETCH_ALERT_CATEGORIES,
  FETCH_ALERT_STATUSES,
  FETCH_ALERT_TYPES,
  SET_ALERTS_DATE_FILTER,
  UPDATE_ALERT_IN_STORE,
} from "../actions/types";
import { normalize } from "./index";
import moment from "moment";

const initialState = {
  all: {},
  filter: {
    startDate: moment().subtract(2, "week").hour(0).minute(0).second(0).millisecond(0),
    endDate: null,
  },
  categories: undefined,
  statuses: undefined,
  types: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ALERTS_DATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case UPDATE_ALERT_IN_STORE:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.alertId]: {
            ...state.all[action.payload.alertId],
            status_id: action.payload.newStatusId,
            status_note: action.payload.newStatusNote,
          },
        },
      };
    case `${FETCH_ALERTS}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_ALERTS}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        all: {
          ...state.all,
          ...normalize(action.payload.data),
        },
      };
    case `${FETCH_ALERT_CATEGORIES}_FULFILLED`:
      return {
        ...state,
        categories: normalize(action.payload.data),
      };
    case `${FETCH_ALERT_STATUSES}_FULFILLED`:
      return {
        ...state,
        statuses: normalize(action.payload.data),
      };
    case `${FETCH_ALERT_TYPES}_FULFILLED`:
      return {
        ...state,
        types: normalize(action.payload.data),
      };
    default:
      return state;
  }
}
