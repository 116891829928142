import React from "react";
import { connect } from "react-redux";
import DeleteIconbutton from "../common/DeleteIconButton";
import { deleteUpdate } from "../store/actions";

function DeleteUpdateButton({ update, deleteUpdate }) {
  return (
    <DeleteIconbutton
      itemId={update.id}
      description="Are you sure you want to delete this record?"
      deleteItemAction={deleteUpdate}
    />
  );
}

export default connect(null, { deleteUpdate })(DeleteUpdateButton);
