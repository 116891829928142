import React from "react";

export default ({ titleLeft, titleRight, value, onToggle }) => {
  const isRightActive = value === titleRight;

  return (
    <div className="d-flex justify-center btn-group btn-group-toggle" data-toggle="buttons">
      <button
        type="button"
        onClick={() => onToggle(titleLeft)}
        className={`btn btn-${isRightActive ? "default" : "primary"} w-sm`}
      >
        {titleLeft}
      </button>

      <button
        type="button"
        onClick={() => onToggle(titleRight)}
        className={`btn btn-${isRightActive ? "primary" : "default"} w-sm`}
      >
        {titleRight}
      </button>
    </div>
  );
};
