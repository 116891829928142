import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, NavLink, Switch } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
  withAuthenticator,
} from "aws-amplify-react";
import UpdatesPage from "./UpdatesPage";
import ApiTestPage from "./ApiTestPage";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { config } from "./aws_config";
import AuthTheme from "./auth/AuthTheme";
import IntakesPage from "./intake/IntakesPage";
import AlertsPage from "./AlertsPage";
import ReportAccessPage from "./ReportsPage";
import { getCurrentAuthenticatedUser } from "./store/actions";
import { withMixpanel } from "react-mixpanel-browser";
import { idleTimerSecs } from "./constants";
import "./css/loading-btn.css";
import "./css/style.css";
import "./css/site.css";
import FacilityPage from "./FacilityPage";

Amplify.configure(config);

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = { isSideMenuOpen: false, isNavbarOpen: false };
  }
  componentDidMount() {
    const { getCurrentAuthenticatedUser, mixpanel } = this.props;
    typeof getCurrentAuthenticatedUser === "function" &&
      getCurrentAuthenticatedUser().then((data) => {
        const {
          value: { attributes: { email } = {}, signInUserSession: { accessToken: { jwtToken } = {} } = {} } = {},
        } = data;
        if (jwtToken && email) {
          mixpanel.identify(email);
          mixpanel.track("user log in", { email: email });
        }
      });
    if (!process.env.REACT_APP_IS_LOCAL) {
      this.idleLogout();
    }
  }

  idleLogout = () => {
    let idleTimer;
    const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

    events.forEach((name) => document.addEventListener(name, resetTimer, true));

    function logout() {
      events.forEach((name) => document.removeEventListener(name, resetTimer, true));
      Auth.signOut();
    }

    function resetTimer() {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(logout, idleTimerSecs * 1000);
    }
  };

  doLink = (path) => {
    const { baseRoute = "" } = this.props;
    return `${baseRoute}${path}`;
  };

  logout = () => {
    Auth.signOut();
  };

  toggleMenu = () => this.setState((prevState) => ({ ...prevState, isSideMenuOpen: !prevState.isSideMenuOpen }));
  toggleNavbar = () => this.setState((prevState) => ({ ...prevState, isNavbarOpen: !prevState.isNavbarOpen }));

  render() {
    const { userEmail = null, mixpanel } = this.props;
    const { isSideMenuOpen, isNavbarOpen } = this.state;
    return (
      <Router>
        <>
          <div id="header" className="clearfix">
            <div className="color-line" />
            <nav className="navbar navbar-default" role="navigation">
              <div className="navbar-header">
                <a href="/" className="navbar-brand">
                  <img src="/static/images/medasync-logo.svg" alt="Medasync Logo" />
                </a>
                <button className="navbar-toggle collapsed" type="button" onClick={this.toggleMenu}>
                  <i className="fa fa-fw fa-bars fa-lg" />
                </button>
                <button type="button" className="navbar-toggle collapsed" onClick={this.toggleNavbar}>
                  <i className="fa fa-fw fa-user fa-lg" />
                </button>
              </div>
              <div
                className={`header-nav navbar-collapse ${isNavbarOpen ? "d-flex justify-end" : "collapse"}`}
                id="Navbar"
              >
                <ul className="nav navbar-nav navbar-right no-borders">
                  {userEmail && (
                    <li>
                      <p className="p-sm">Hello, {userEmail}</p>
                    </li>
                  )}
                  <li>
                    <a className="p-sm" href="mailto:support@medasync.com?subject=Contact Us">
                      Contact Support
                    </a>
                  </li>
                  <li>
                    <button
                      className="btn btn-primary m-t-sm m-b-sm m-r m-l"
                      style={{
                        color: "white",
                      }}
                      onClick={() => {
                        this.logout();
                        mixpanel.reset();
                      }}
                    >
                      Sign Out
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <nav
            id="menu"
            className={`ps ps--theme_default ${isSideMenuOpen ? "open" : ""}`}
            data-ps-id="6162f2df-a76d-9b56-4553-28096a4d777e"
          >
            <ul className="nav" id="side-menu" role="menu">
              <li role="menuitem">
                <NavLink exact to={this.doLink("/updates")} activeClassName={"active"}>
                  <i className="fa fa-fw fa-flag" />
                  Updates
                </NavLink>
              </li>
              <li role="menuitem">
                <NavLink to={this.doLink("/intakes")} activeClassName={"active"}>
                  <i className="fa fa-fw fa-user-plus" />
                  Intake
                </NavLink>
              </li>
              <li role="menuitem">
                <NavLink to={this.doLink("/patients")} activeClassName={"active"}>
                  <i className="fa fa-fw fa-user" />
                  Patients
                </NavLink>
              </li>
              <li role="menuitem">
                <NavLink to={this.doLink("/alerts")} activeClassName={"active"}>
                  <i className="fa fa-fw fa-bell" />
                  Alerts
                </NavLink>
              </li>
              <li role="menuitem">
                <NavLink to={this.doLink("/reports")} activeClassName={"active"}>
                  <i className="fa fa-fw fa-bar-chart" />
                  Analytics
                </NavLink>
              </li>
              {process.env.REACT_APP_IS_LOCAL && (
                <li role="menuitem">
                  <NavLink to={this.doLink("/apitest")} activeClassName={"active"}>
                    <i className="fa fa-fw fa-close" />
                    Api Test
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>

          <div id="Wrapper">
            <main className="content">
              <Switch>
                <Route path={this.doLink("/")} exact component={AlertsPage} />
                <Route path={this.doLink("/updates")} exact component={UpdatesPage} />
                <Route path={this.doLink("/alerts")} exact component={AlertsPage} />
                <Route path={this.doLink("/reports")} component={ReportAccessPage} />
                <Route path={this.doLink("/intakes")} component={IntakesPage} />
                <Route path={this.doLink("/patients")} component={FacilityPage} />
                {process.env.REACT_APP_IS_LOCAL && <Route path={this.doLink("/apitest")} component={ApiTestPage} />}
              </Switch>
            </main>
            <footer>
              <span>{"\u00A9"} 2018 - MedaSync </span>
            </footer>
          </div>
        </>
      </Router>
    );
  }
}

App.propTypes = {
  baseRoute: PropTypes.string,
};

function mapStateToProps(state) {
  const { user = {} } = state;
  return {
    userEmail: user.email,
  };
}

export default connect(mapStateToProps, { getCurrentAuthenticatedUser })(
  withAuthenticator(
    withMixpanel(App),
    false,
    [<ConfirmSignIn />, <ConfirmSignUp />, <ForgotPassword />, <RequireNewPassword />, <SignIn />, <VerifyContact />],
    null,
    AuthTheme
  )
);
