import React from "react";
import { connect } from "react-redux";
import { defaultConfig } from "../Medications";
import { highCostMedicationsForIntake, medicationsForIntake } from "../../store/selectors";
import Table from "../../common/Table";

const config = {
  columns: defaultConfig.columns.filter((c) => c.title === "Medication Name" || c.title === "Total Cost"),
};

export class HighCostMedications extends React.Component {
  render() {
    const { highCostMedications = [], total } = this.props;
    return highCostMedications.length > 0 ? (
      <>
        <strong className="h3">{highCostMedications.length}</strong>
        <span className="h5 text-muted"> out of {total}</span>
        <div className="h4">
          Medications are <strong>high cost</strong>.
        </div>
        <div className="text-left">
          <Table data={highCostMedications} config={config} />
        </div>
      </>
    ) : (
      <h4>No High Cost Medications</h4>
    );
  }
}

function mapStateToProps(_, initialProps) {
  const { intakeId } = initialProps,
    highCostMedicationsSelector = highCostMedicationsForIntake(intakeId),
    medicationsSelector = medicationsForIntake(intakeId);
  return (state) => ({
    intake: state.intake.all[intakeId],
    highCostMedications: highCostMedicationsSelector(state),
    total: (medicationsSelector(state) || []).length,
  });
}

export default connect(mapStateToProps)(HighCostMedications);
