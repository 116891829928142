import React from "react";
import moment from "moment";
import Table from "../../common/Table";

const config = {
  columns: [
    {
      title: "Date/Time",
      key: "dateTime",
      transformer: (colVal) => moment(colVal).format("M/DD/YYYY"),
      sortValue: ({ dateTime }) => moment(dateTime).unix(),
      sortable: true,
    },
    {
      title: "ADL Index",
      key: "adlIndex",
      sortable: true,
    },
  ],
  defaultSort: {
    column: 0,
    direction: "descending",
  },
};

const ADLsTable = ({ data, ...props }) => {
  return <Table data={data} config={config} />;
};

export default ADLsTable;
