import React, { useState } from "react";
import moment from "moment";
import Table from "../common/Table";
import Filter from "../common/Filter";
import { DateFilter } from "../common/DateFilter";
import LoadingIndicator from "../common/LoadingIndicator";
import { useOrdersForStay } from "../api/caseManagementApi";

const config = {
  columns: [
    {
      title: "Date",
      key: "date",
      transformer: (data) => moment(data).format("M/DD/YYYY"),
      sortValue: (data) => moment(data.date).unix(),
      sortable: true,
    },
    {
      title: "Category",
      key: "category",
      sortable: true,
    },
    {
      title: "Status",
      key: "status",
      sortable: true,
    },
    {
      title: "Description",
      key: "description",
      sortable: true,
    },
  ],
  defaultSort: {
    column: 0,
    direction: "descending",
  },
  search: {
    keys: [
      {
        name: "description",
        weight: 1,
      },
    ],
    placeholder: "Search by description",
    searchInputStyle: { flex: "1 1 auto" },
  },
};

const tableHeaderStyle = { flexDirection: "column" };

const filterOptions = {
  status: [
    "Active",
    "Completed",
    "Discontinued",
    "On Hold",
    "Pending confirmation",
    "Pending confirmation (Discontinue)",
  ].map((s) => {
    return {
      label: s,
      value: s,
    };
  }),
  category: [
    "Medication",
    "Laboratory",
    "Dietary",
    "Diagnostic",
    "Enteral",
    "Supplement",
    "Admin",
    "Other",
    "UDA - Wound",
  ].map((s) => {
    return {
      label: s,
      value: s,
    };
  }),
};

export const Orders = (props) => {
  const { stay } = props;

  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    status: [{ label: "Active", value: "Active" }],
    category: [],
  });
  const ordersQuery = useOrdersForStay(stay.id);

  if (ordersQuery.isLoading) {
    return <LoadingIndicator />;
  }

  const visibleOrders = ordersQuery.data.filter((order) => {
    const date = moment(order.date);
    return (
      (!filters.status.length || filters.status.map(({ value }) => value).includes(order.status)) &&
      (!filters.category.length || filters.category.map(({ value }) => value).includes(order.category)) &&
      (!filters.startDate || date.isSameOrAfter(filters.startDate, "day")) &&
      (!filters.endDate || date.isSameOrBefore(filters.endDate, "day"))
    );
  });

  const handleFilterChange = (filterKey, selectedValues) => {
    setFilters((x) => ({ ...x, [filterKey]: selectedValues }));
  };

  const handleDateFilterChange = (selectedValue) => {
    setFilters((x) => ({ ...x, startDate: selectedValue.startDate, endDate: selectedValue.endDate }));
  };

  return ordersQuery.data.length > 0 ? (
    <Table
      data={visibleOrders}
      config={config}
      headerStyle={tableHeaderStyle}
      searchContent={
        <div className="d-flex justify-end p-xs">
          <DateFilter endDate={filters.endDate} startDate={filters.startDate} setDateFilter={handleDateFilterChange} />
        </div>
      }
      headerContent={Object.keys(filters)
        .filter((filterKey) => filterKey === "status" || filterKey === "category")
        .map((filterKey) => {
          const label = filterKey.charAt(0).toUpperCase() + filterKey.slice(1);
          return (
            <Filter
              className="p-xs"
              key={filterKey}
              label={label}
              value={filters[filterKey]}
              options={filterOptions[filterKey]}
              onChange={(values) => handleFilterChange(filterKey, values)}
              isMulti
            />
          );
        })}
    />
  ) : (
    <div style={{ marginTop: 20 }}>There are no orders for this patient.</div>
  );
};
