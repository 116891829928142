import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateRateHistory } from "../store/actions";
import Filter from "../common/Filter";
import SnackBarMessage from "../common/SnackBarMessage";
import { useMixpanel } from "react-mixpanel-browser";
import { getPlansForPayerSelector } from "../store/selectors";

function RateHistoryDialog({ rateHistory, updateRateHistory, insurancePlans = [], user: { email } = {} }) {
  const mixpanel = useMixpanel();
  const [isModalOpen, toggleIsModalOpen] = useState(false);
  const [isMessageShown, setIsMessageShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(alert.status || "Open");
  const planOptions = insurancePlans
    .map((plan) => ({ label: plan.careLevel, value: plan.id }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  useEffect(() => {
    const hideNewMessageTimer = setTimeout(() => setIsMessageShown(false), 2900);
    return () => {
      clearTimeout(hideNewMessageTimer);
    };
  }, [isMessageShown]);

  return (
    <>
      <button className="btn btn-link d-flex align-center" onClick={() => toggleIsModalOpen(!isModalOpen)}>
        <i className="fa fa-2x fa-pencil-square text-primary clickable m-r-sm" aria-label="Edit Rate History" />
        {status}
      </button>
      <Modal open={isModalOpen} onClose={() => toggleIsModalOpen(!isModalOpen)} title="Manage Rate History">
        <p>
          Payer: <b>{rateHistory.payer && rateHistory.payer.displayName}</b>
        </p>
        <p>
          Care Level: <b>{rateHistory.careLevel}</b>
        </p>
        <Formik
          initialValues={{
            careLevel: rateHistory.careLevel,
          }}
          validationSchema={Yup.object().shape({
            careLevel: Yup.string().required("Required"),
          })}
          onSubmit={(values, actions) => {
            updateRateHistory(rateHistory.id, values.careLevel)
              .then(() => {
                setStatus(values.status);
                actions.setSubmitting(false);
              })
              .catch((err) => {
                setStatus(alert.status);
                setErrorMessage(err && err.message);
                setIsMessageShown(true);
              });
            toggleIsModalOpen(!isModalOpen);
            mixpanel.track("RateHistory careLevel changes", { email: email });
          }}
          render={({ handleSubmit, isSubmitting, setFieldValue, touched, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="control-label" htmlFor="careLevel">
                  Care Level
                </label>
                <Filter
                  id="careLevel"
                  className="w-xs m-b-n-xs"
                  selectHeight="30px"
                  isSearchable={false}
                  defaultValue={{ label: rateHistory.insurancePlan.careLevel, value: rateHistory.insurancePlan.id }}
                  key={planOptions.length}
                  options={planOptions}
                  onChange={(option) => {
                    setFieldValue("careLevel", option.value);
                  }}
                />
                {touched.careLevel && errors.careLevel && (
                  <div className="help-block text-danger">{errors.careLevel}</div>
                )}
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                Save
              </button>
            </form>
          )}
        />
      </Modal>
      <SnackBarMessage isMessageShown={isMessageShown} snackBarClassName="bg-colour-warning" message={errorMessage} />
    </>
  );
}

function mapStateToProps(state, { rateHistory: { payerId } }) {
  return {
    insurancePlans: payerId ? getPlansForPayerSelector(payerId)(state) : [],
    user: state.user,
  };
}

export default connect(mapStateToProps, { updateRateHistory })(RateHistoryDialog);
