import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, matchPath } from "react-router-dom";
import { fetchAnalyticsReports } from "./store/actions";
import Select from "react-select";
import ReportDisplayPage from "./ReportDisplay";
import { withMixpanel } from "react-mixpanel-browser";

export class ReportAccessPage extends Component {
  componentDidMount() {
    const { fetchAnalyticsReports, mixpanel, user: { email } = {} } = this.props;
    typeof fetchAnalyticsReports === "function" && fetchAnalyticsReports();
    mixpanel && mixpanel.track("visit analytics screen", { email: email });
  }

  componentDidUpdate() {
    const {
      reports = [],
      history = {},
      location: { pathname },
    } = this.props;
    const selectedReportId = this.getParams(pathname).id;
    if (reports.length && !selectedReportId && history.action !== "POP") {
      const reportKey = reports[0].key;
      this.props.history.replace(`/reports/${reportKey}`);
    }
  }

  setSelectedReportId = (option) => {
    const reportKey = option.value;
    this.props.history.push(`/reports/${reportKey}`);
  };

  getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
      path: `/reports/:id`,
    });
    return (matchProfile && matchProfile.params) || {};
  };

  render() {
    const {
      reports = [],
      match: { path },
      location: { pathname },
    } = this.props;
    const selectedReportId = this.getParams(pathname).id;
    const options = reports.map((report) => ({ value: report.key, label: report.ReportName }));
    const selectedOption = options.filter((option) => option.value === selectedReportId)[0];
    return (
      <div>
        {options && (
          <Select name="reportSelect" options={options} onChange={this.setSelectedReportId} value={selectedOption} />
        )}
        <hr />
        {options.length > 0 && (
          <Switch>
            <Route exact path={`${path}/:id`} component={ReportDisplayPage} />
          </Switch>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reports: Object.values(state.reports.reports),
    user: state.user,
  };
}

export default connect(mapStateToProps, { fetchAnalyticsReports })(withMixpanel(ReportAccessPage));
