import React from "react";

export default ({ name, label, touched, errors, values, handleChange, prefix, suffix }) => {
  const inputElement = <input id={name} onChange={handleChange} value={values[name]} className="form-control" />;
  return (
    <div className="form-group">
      <label className="control-label" htmlFor={name}>
        {label}
      </label>
      {prefix || suffix ? (
        <div className="input-group">
          {prefix && <span className="input-group-addon">{prefix}</span>}
          {inputElement}
          {suffix && <span className="input-group-addon">{suffix}</span>}
        </div>
      ) : (
        inputElement
      )}
      {touched[name] && errors[name] && <div className="help-block text-danger">{errors[name]}</div>}
    </div>
  );
};
