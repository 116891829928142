import React from "react";

export default ({ data = [], className, style }) => {
  return (
    <div className={`striped m-l-n-md border-top ${className}`} style={style}>
      {data.constructor === Array ? (
        data.map((value, i) => (
          <div key={i} style={{ padding: "5px 0 5px 20px" }}>
            {value}
          </div>
        ))
      ) : (
        <div style={{ padding: "5px 0 5px 20px" }}>{data}</div>
      )}
    </div>
  );
};
