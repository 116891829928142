import { API } from "aws-amplify";
import { apiName as orcaApiName } from "../store/actions/orca";
import axios from "axios";
import moment from "moment";

export function useFullName(patient) {
  const { firstName, lastName } = patient;
  return `${firstName ? `${firstName} ` : ""}${lastName || ""}`;
}

export function convertToKilobytes(bytes) {
  return Math.ceil(bytes / 1024);
}

export function launchEmailClient(recordType) {
  window.location = encodeURI(
    `mailto:?subject=A MedaSync ${recordType} has been shared with you&body=A Medasync ${recordType} has been shared with you. Please navigate to the link below:\n${window.location.href}`
  );
}
export const uploadFile = async ({ file = {}, types }, facilityId) => {
  const { name, size } = file;
  const { objectKey: s3Key, uploadUrl, uploadHeaders } = await API.post(orcaApiName, "/upload-referral-url", {
    body: { facilityId, fileName: name },
  });
  await axios.put(uploadUrl, file, {
    headers: uploadHeaders,
  });

  return {
    name,
    size,
    s3Key,
    types,
  };
};

export function copyToClipboard(string) {
  return navigator.clipboard.writeText(string);
}

export function therapyEventToString({ date, setting, OT, PT, ST, total }) {
  return `${moment(date).format("MMMM Do YYYY").padEnd(25, " ")}   ${
    setting && setting.toString().padStart(4, " ").padEnd(20, " ")
  }   OT: ${OT.toString().padStart(4, " ")},   PT: ${PT.toString().padStart(4, " ")},   ST: ${ST.toString().padStart(
    4,
    " "
  )},    total: ${total || 0}\n`;
}

export function getTherapyMinutesAsText(therapyMinutesByType) {
  return therapyMinutesByType
    .map((therapyEvent) =>
      therapyEvent.setting && therapyEvent.setting.constructor === Array
        ? therapyEvent.setting
            .map((setting, i) => {
              const event = {
                date: therapyEvent.date,
                setting: setting,
                OT: therapyEvent.OT[i],
                PT: therapyEvent.PT[i],
                ST: therapyEvent.ST[i],
                total: therapyEvent.total[i],
              };
              return therapyEventToString(event);
            }, therapyEvent)
            .join("")
        : therapyEventToString(therapyEvent)
    )
    .join("");
}
