import React from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, ResponsiveContainer } from "recharts";
import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent";
import ToggleButton from "../../common/ToggleButton";
import orderBy from "lodash/orderBy";

const sumTotal = (total) => {
  if (total.constructor !== Array) return total;
  return total.reduce((sum, x) => (sum += x), 0);
};

const CustomTooltip = (props) => {
  if (!props.active) {
    return null;
  }

  const toolTipPayload = props.payload[0] ? props.payload[0].payload : {};
  const payload = [
    {
      name: "Day",
      value: toolTipPayload.dayInStay,
    },
    {
      name: "Date",
      value: toolTipPayload.date,
    },
    {
      name: "Minutes",
      value: sumTotal(toolTipPayload.total),
    },
  ];

  return <DefaultTooltipContent payload={payload} />;
};

export class TherapyGraph extends React.Component {
  state = {
    chartType: "Dates In Stay",
  };

  toggleChartType = (type) => {
    this.setState({
      chartType: type,
    });
  };

  cleanTherapyData = (data) => {
    data.map((d) => {
      if (d["total"] && d["total"].constructor === Array) {
        d["total"] = d["total"].reduce((sum, x) => (sum += x), 0);
      }
      return d;
    });
    return orderBy(data, "dateTime", "asc");
  };

  render() {
    const { width = 500, height = 250 } = this.props;

    const data = this.cleanTherapyData(this.props.data);
    const showDates = this.state.chartType === "Dates In Stay";

    return data.length > 0 ? (
      <>
        <ResponsiveContainer width={width} height={height}>
          <BarChart data={data}>
            <CartesianGrid stroke="1" />

            <XAxis dataKey={showDates ? "date" : "dayInStay"} />
            <YAxis domain={[0, "dataMax + 10"]} />

            <Tooltip content={CustomTooltip} />
            <Bar dataKey="total" fill="#8884d8" />
            {/* <ReferenceLine y={therapyMinutesPerDay} stroke="red" strokeDasharray="3 3">
              <Label position="insideRight" value="Max Reimbursement" />
            </ReferenceLine> */}
          </BarChart>
        </ResponsiveContainer>

        <ToggleButton
          titleLeft="Days In Stay"
          titleRight="Dates In Stay"
          value={this.state.chartType}
          onToggle={this.toggleChartType}
        />
      </>
    ) : (
      <p className="m-l-sm m-b-none" style={{ marginTop: 10 }}>
        There is no data for this patient.
      </p>
    );
  }
}

export default TherapyGraph;
