import React, { useState } from "react";
import moment from "moment";
import ADLsGraph from "./ADLsGraph";
import ADLsTable from "./ADLsTable";
import ToggleButton from "../../common/ToggleButton";
import LoadingIndicator from "../../common/LoadingIndicator";
import { DateFilter } from "../../common/DateFilter";
import { isToggleTurnedOn } from "../../common/FeatureToggle";
import { useAdlsForStay } from "../../api/caseManagementApi";
import { orderBy } from "lodash";

export const Adls = (props) => {
  const { stay } = props;

  const [filters, setFilters] = useState({ startDate: null, endDate: null });
  const [chartType, setChartType] = useState("Chart");

  const adlsQuery = useAdlsForStay(stay.id);

  if (adlsQuery.isLoading) {
    return <LoadingIndicator />;
  }

  const mergedAdls = getMergedAdls(adlsQuery.data, stay.admitDate);

  const handleDateFilterChange = (selectedValue) => {
    setFilters((x) => ({ ...x, ...selectedValue }));
  };

  const toggleChartType = (type) => {
    setChartType(type);
  };

  const canShowGraph = isToggleTurnedOn("ADL_GRAPH");
  const showChart = chartType === "Chart";

  const visibleAdls = mergedAdls.filter((record) => {
    const date = moment(record.dateTime);
    return (
      (!filters.startDate || date.isSameOrAfter(filters.startDate, "day")) &&
      (!filters.endDate || date.isSameOrBefore(filters.endDate, "day"))
    );
  });

  return mergedAdls.length > 0 ? (
    <>
      <div className="d-flex justify-end p-xs">
        <DateFilter endDate={filters.endDate} startDate={filters.startDate} setDateFilter={handleDateFilterChange} />
      </div>

      {canShowGraph && (
        <div className="bg-light m-l-sm m-r-sm" style={{ marginTop: 10 }}>
          <ToggleButton titleLeft="Graph" titleRight="Chart" value={chartType} onToggle={toggleChartType} />
        </div>
      )}

      <div className="col" style={{ marginTop: 20 }}>
        {showChart ? (
          <ADLsTable data={visibleAdls} />
        ) : (
          canShowGraph && <ADLsGraph width="100%" data={visibleAdls} stay={stay} />
        )}
      </div>
    </>
  ) : (
    <div style={{ marginTop: 20 }}>There is no data available.</div>
  );
};

export const BED_MOBILITY_CATEGORY = "RNS: Bed Mobility";
export const TRANSFER_CATEGORY = "RNS: Transfer";
export const WALKING_CATEGORY = "RNS: Walking";
export const DRESS_GROOM_CATEGORY = "RNS: Dressing / Grooming";
export const EAT_SWALLOW_CATEGORY = "RNS: Eating / Swallowing";
export const ADL_INDEX_CATEGORY = "ADL Index";

const getMergedAdls = (adls, admitDate) => {
  const mergedAdls = {};
  adls.forEach((adl) => {
    const timeDiff = moment(adl.assessmentDate).utc();
    const merged = mergedAdls[adl.assessmentDate] ?? {
      dateTime: adl.assessmentDate,
      bed: 0,
      transfer: 0,
      walk: 0,
      dressGroom: 0,
      eatSwallow: 0,
      adlIndex: 0,
      dayInStay: Math.ceil(moment.duration(timeDiff.diff(admitDate)).asDays()) + 1,
    };

    switch (adl.category) {
      case BED_MOBILITY_CATEGORY:
        merged.bed = adl.score;
        break;
      case TRANSFER_CATEGORY:
        merged.transfer = adl.score;
        break;
      case WALKING_CATEGORY:
        merged.walk = adl.score;
        break;
      case DRESS_GROOM_CATEGORY:
        merged.dressGroom = adl.score;
        break;
      case EAT_SWALLOW_CATEGORY:
        merged.eatSwallow = adl.score;
        break;
      default:
        if (adl.category.includes(ADL_INDEX_CATEGORY)) {
          merged.adlIndex = adl.score;
        }
        break;
    }
    mergedAdls[adl.assessmentDate] = merged;
  });
  return orderBy(Object.values(mergedAdls), "dateTime", "asc");
};
