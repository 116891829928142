export const statusOptions = [
  "Pending",
  "Submitted",
  "Approved",
  "Denied",
  "Expired",
  "Admitted",
  "Reviewed",
  "DC Home",
  "DC other SNF",
  "Approved and Pending Hospital DC plans",
  "Approved but Discharged to other SNF",
  "Approved but Discharged to Home",
  "Approved but Discharged to other type of institution",
  "Approved, but Insurance Denied",
  "Approved but Expired",
  "Denied for Behaviors",
  "Denied for Medical Conditions",
  "Denied for Insurance",
  "Denied for no Insurance",
  "Denied for no Discharge plans",
  "Denied for Other reason",
].map((status) => ({ label: status, value: status.toUpperCase() }));
